import React from "react";

const SUARL = () => {
return (
<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M47.4869 23.834C46.4905 27.6436 44.1654 28.4828 42.9248 28.6511C42.1606 30.1789 40.4808 33.3013 38.8005 34.8702C38.1288 35.497 37.2559 36.0261 36.2063 36.4428C34.8661 36.9745 33.4507 37.2442 31.9999 37.2442C30.5491 37.2442 29.1337 36.9745 27.7936 36.4428C26.7442 36.0264 25.8711 35.4973 25.1994 34.8702C23.5188 33.3016 21.839 30.1789 21.0748 28.6511C19.8345 28.4828 17.5094 27.6439 16.513 23.834C15.9801 21.7967 16.1165 20.136 16.9188 18.8977C17.2734 18.3505 17.7032 17.9778 18.1035 17.7254C18.1046 17.3187 18.1216 16.8095 18.1688 16.2304C17.4482 15.229 16.4685 13.0684 17.6818 9.57126C18.9643 5.87411 21.8639 5.30315 23.2634 5.25367C23.7366 4.3741 24.6305 3.12532 26.2538 1.97726C28.0557 0.702129 30.4086 0 32.8789 0C35.9187 0 38.9776 1.06988 41.7246 3.09399C46.1784 6.37567 45.7969 13.6552 45.6876 14.9769C45.8493 16.0848 45.8941 17.0458 45.8961 17.7251C46.2964 17.9775 46.7262 18.3502 47.0808 18.8975C47.883 20.136 48.0195 21.7964 47.4869 23.834ZM60.5123 52.2029V62.536C60.5123 63.3444 59.8567 64 59.0483 64H4.95129C4.14288 64 3.4873 63.3444 3.4873 62.536V52.2029C3.4873 47.9696 6.02703 44.2183 9.95755 42.646L22.7572 37.5261C23.1255 37.3791 23.5378 37.387 23.9 37.5498C24.2619 37.712 24.5427 38.0145 24.6774 38.3875L27.8952 47.2836L28.5323 45.4519L27.5187 42.9739C27.3342 42.5224 27.3863 42.0086 27.6583 41.6036C27.9303 41.1987 28.3859 40.9557 28.8737 40.9557H35.1261C35.6139 40.9557 36.0698 41.1987 36.3415 41.6036C36.6135 42.0086 36.6657 42.5224 36.4812 42.9739L35.4675 45.4519L36.1047 47.2836L39.3225 38.3875C39.4572 38.0145 39.738 37.712 40.0999 37.5498C40.4621 37.3873 40.8746 37.3794 41.2427 37.5261L54.0423 42.646C57.9728 44.2183 60.5123 47.9696 60.5123 52.2029ZM52.7192 53.3264C52.7192 52.5179 52.0636 51.8624 51.2552 51.8624H41.426C40.6176 51.8624 39.962 52.5179 39.962 53.3264V57.8911C39.962 58.6995 40.6176 59.3551 41.426 59.3551H51.2552C52.0636 59.3551 52.7192 58.6995 52.7192 57.8911V53.3264Z" fill="#3240FF"/>
</svg>
);
};

export default SUARL;
