import React, { useEffect, useState } from "react";
import {
  useHistory,
  Link,
} from "react-router-dom";
import { Drawer, Menu } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { CLOSE_SERVICES_SIDER } from "../../actions/ui-actions/types";
import SmallPromotionalCard from "../../components/promotional-cards-components/SmallPromotionalCard";

const ServicesSidebar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedKey, setSelectedKey] = useState("");
  const servicesSiderOpen = useSelector(
    (state: RootState) => state.uiReducer.servicesSiderOpen
  );
  const promotions = useSelector((state: RootState) => state.serivcesReducer.promotions);
  const [sidebarPromotions, setSidebarPromotions] = useState([]);
  const screenWidth = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;

  const onClose = () => {
    dispatch({ type: CLOSE_SERVICES_SIDER });
  }
  const getSelectedKey = (path : any) => {
    if (path.includes("services")) {
      const key = path.substring(1);
      setSelectedKey(key);
    } else {
      setSelectedKey("");
    }
  }
  useEffect(() => {
    getSelectedKey(history.location.pathname);
  })
  useEffect(() => {
    history.listen((location: any) => {
      getSelectedKey(location.pathname);
    })
  })
  useEffect(() => {
    if (promotions && promotions.length > 0) {
      setSidebarPromotions(promotions.filter((promo : any, i: number) => promo.in_services_sidebar));
    }
  }, [promotions])
  const getRandomPromotion = () => {
    let promotion = null;
    if(sidebarPromotions.length > 0){
      const index = Math.floor(Math.random() * sidebarPromotions.length);
      promotion = sidebarPromotions[index];
    }
    return promotion
  }
  return (
    <Drawer
      title={
        <>
          <h2>Nos services</h2>
          <hr />
          <p>Profitez d’une multitude de services avec Domicilier.tn De la domiciliation à la création d’entreprise....</p>
        </>
      } placement="left" onClose={onClose} visible={servicesSiderOpen} className={"services-sidebar"} width={screenWidth < 992 ? "80%" : 600}>
      <div className={"sidebar-wrapper"}>
        <Menu
          selectedKeys={[selectedKey]}
        >
          <Menu.Item key={"services"}>
            <Link to={"/services-domiciliation-en-tunisie"} className={"sidebar-menu-link"} onClick={onClose}>Tous les services <ArrowRightOutlined /></Link>
          </Menu.Item>
          <Menu.Item key={"services/domiciliation"}>
            <Link to={"/services-domiciliation-en-tunisie/domiciliation"} className={"sidebar-menu-link"} onClick={onClose}>Domiciliation</Link>
          </Menu.Item>
          <Menu.Item key={"services/create-entreprise"}>
            <Link to={"/services-domiciliation-en-tunisie/création-entreprise-tunisie"} className={"sidebar-menu-link"} onClick={onClose}>Création d’entreprise</Link>
          </Menu.Item>
        </Menu>
        {sidebarPromotions.length > 0 ? (
          <SmallPromotionalCard
            data={getRandomPromotion()}
          />
        ) : null}
      </div>
    </Drawer>
  );
};

export default ServicesSidebar;
