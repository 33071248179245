import React, { useEffect, useState } from "react";
import { Row, Col, Pagination, Spin, Menu } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../reducers";
import ApplyModel from "./ApplyModel";
import JobAppliedModel from "./JobAppliedModel";
import OffersSearchBar from "./OffersSearchBar";
import { getJobOffers, getDepartments } from "../../actions/services-actions/actions";
import { LoadingOutlined } from '@ant-design/icons';
import JobOfferCard from "./JobOfferCard";

const LoadingOutlinedIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;

const JobOffersList = () => {
  const dispatch = useDispatch();
  const jobOffers = useSelector((state: RootState) => state.serivcesReducer.jobOffers);
  const isLoadingJobs = useSelector((state: RootState) => state.serivcesReducer.isLoadingJobs);
  const lastPage = useSelector((state: RootState) => state.serivcesReducer.jobOffersLastPage);
  const total = useSelector((state: RootState) => state.serivcesReducer.jobOffersTotal);
  const departments = useSelector((state: RootState) => state.serivcesReducer.departments);
  const isLoadingDepartments = useSelector((state: RootState) => state.serivcesReducer.isLoadingDepartments);
  const applied = useSelector((state: RootState) => state.serivcesReducer.applied);
  const [applicationModal, setApplicationModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [selectedKey, setSelectedKey] = useState("all");
  const [searchValues, setSearchValues]: [any, (key: any) => void] = useState<any>(null);
  const [page, setPage] = useState(1);
  const useMountEffect = (fun: any) => useEffect(fun, [])
  useEffect(() => {
    if (applied) {
      setApplicationModal(false);
      setSuccessModal(true);
    }
  }, [applied])
  const fetchJobOffers = () => {
    let params: any = {};
    params.page = page;
    if (searchValues && searchValues.key) {
      params.key = searchValues.key;
      params.filter = searchValues.filter;
    }
    dispatch(getJobOffers(params));
  }

  const onChangePage = (next: number) => {
    setPage(next);
    const jobOffersList = document.getElementById('job-offers-list');
    if (jobOffersList) {
      jobOffersList.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  }

  useEffect(() => {
    if (searchValues) {
      if (searchValues.key) {
        setPage(1);
      }
    }
    if (page <= lastPage) {
      fetchJobOffers();
    }
  }, [page, searchValues])
  useEffect(() => {
    if (selectedKey !== "all") {
      setSearchValues({ key: selectedKey, filter: 'department' })
    } else {
      setSearchValues(null);
    }
  }, [selectedKey])
  useMountEffect(() => {
    dispatch(getDepartments())
  })
  return (
    <div className={"job-offers-list"} id={"job-offers-list"}>
      <OffersSearchBar search={(values: any) => setSearchValues(values)} />
      <Row gutter={[24, 24]} justify="space-between">
        <Col xs={24} lg={8}>
          <h3>Departements</h3>
          {isLoadingDepartments ? (
            <div className="spinner-container" style={{ marginTop: "10%", minHeight: "500px" }}>
              <Spin indicator={LoadingOutlinedIcon} size="large" />
            </div>
          ) : (
            <Menu
              selectedKeys={[selectedKey]}
            >
              <Menu.Item
                className={"department-item"}
                key="all"
                onClick={() => setSelectedKey("all")}
              >
                Tout
              </Menu.Item>
              <Menu.Divider />
              {departments.map((department: any, key: number) => (
                <Menu.Item
                  key={department}
                  className={"department-item"}
                  onClick={() => setSelectedKey(department)}
                >
                  {department}
                </Menu.Item>
              ))}
            </Menu>
          )}
        </Col>
        <Col xs={24} lg={16}>
          {isLoadingJobs ? (
            <div className="spinner-container" style={{ marginTop: "10%", minHeight: "500px" }}>
              <Spin indicator={LoadingOutlinedIcon} size="large" />
            </div>
          ) :
            jobOffers && jobOffers.length > 0 ? (
              <div>
                <Row className="job-offers-list-wrapper" gutter={[32, 32]} >
                  {jobOffers && jobOffers.map((jobOffer: any, key: number) => (
                    <Col md={12} xs={24} key={key}>
                      <JobOfferCard
                        jobOffer={jobOffer}
                        apply={() => setApplicationModal(true)}
                      />
                    </Col>
                  ))}
                </Row>
                <Pagination responsive hideOnSinglePage pageSize={6} current={page} onChange={onChangePage} total={total} />
              </div>
            ) : (
              <em style={{ color: "#bfbfbf", textAlign: "center", display: "block" }}>Aucune offre d'emploi</em>
            )}
        </Col>
      </Row>
      <ApplyModel
        visible={applicationModal}
        abort={() => setApplicationModal(false)}
      />
      <JobAppliedModel
        visible={successModal}
        abort={() => setSuccessModal(false)}
      />
    </div >
  );
};

export default JobOffersList;
