import React from "react";

const APIKEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

const MapContainer = ({ address, height }: any) => {
  return (
    address ? (
      <iframe
        title="Maps"
        loading={"lazy"}
        height={height}
        style={{ width: "100%", border: "none", borderRadius: "8px" }}
        src={address ? `https://www.google.com/maps/embed/v1/place?key=${APIKEY}&q=${address.address_line_1.replaceAll(" ", "+")},${address.address_line_2 ? address.address_line_2.replaceAll(" ", "+") + ',' : null}${address.city}+${address.state}&center=${address.lat},${address.long}` : 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3193.190690316755!2d10.143752115145709!3d36.837908879940876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd33e4d0ac7f0b%3A0x3ac1c05544ccdd6!2sAtomart%20design%20studio!5e0!3m2!1sfr!2stn!4v1632740773706!5m2!1sfr!2stn'
        }>
      </iframe >
    ) : null
  );
}

export default MapContainer;