import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import domi from "../../../assets/png/home.png"
import lightEllipse from "../../../assets/png/Light-Ellipse.png";
import { Link } from "react-router-dom";


const CreateEntrepriseFirstBlock = () => {
  const isLoggedIn = useSelector((state: RootState) => state.authReducer.isLoggedIn);
  return (
    <>
      <div className={"create-entreprise-first-block"} style={{ backgroundImage: `url('${domi}')` }}>
        <img src={lightEllipse} alt="Light Ellipse" className={"ellipse-right"} />
        <div className={"max-width-container"}>
          <div className={"content"}>
            <h1>Créer mon entreprise en ligne !</h1>
            <p>Domicilier.tn vous offre une solution clé en main simplifiant les démarches administratives et juridiques pour créer votre entreprise et la gérer au quotidien.</p>
            {isLoggedIn ? null : (
              <div className={"buttons-wrapper"}>
                <a href={process.env.REACT_APP_APP_URL + "/signup"} className={"blue-btn"}>Créer mon entreprise</a>
                <Link to={"/contact-me"} className={"blue-btn"}>Echanger avec un conseiller</Link>
              </div>
            )}

            <div className={"mobile-image-container"}>
              <img src={domi} alt="img" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateEntrepriseFirstBlock;
