import React from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import "./promotional-cards.scss";
import moment from 'moment';
import 'moment/locale/fr'  // without this line it didn't work
import { Link } from "react-router-dom";
import icon1 from '../../assets/png/marketing-campaign.png';
import icon2 from '../../assets/png/brainstorm.png';
moment.locale('fr')

interface SmallPromotionalCardProps {
  data: any;
}

const SmallPromotionalCard: React.FC<SmallPromotionalCardProps> = ({ data }) => {
  const getRandomImage = () => {
    const index = Math.random() * 2;
    if (index > 1) {
      return icon1;
    }
    return icon2
  }
  if (data && data.id) {
    return (
      <div className={"small-promotion-card-wrapper"} style={{ backgroundImage: `url('${getRandomImage()}')` }}>
        <div className={"small-promotion-card-content"}>
          <h3>Alerte Promotion !</h3>
          <div dangerouslySetInnerHTML={{ __html: data.body }} className={"small-promotion-body"}></div>
          <div className={"small-promotion-expiration"}>Offre valable jusqu’au {moment(data.expiry_date).format("DD/MM/YYYY")}</div>
          {data.link ? (
            <Link to={data.link} className="small-promotion-link">{data.link_text} <ArrowRightOutlined/></Link>
          ) : null}
        </div>
        <div className={"mobile-image-container"}>
          <img src={getRandomImage()} alt="img" />
        </div>
      </div>
    )
  } else {
    return null
  }
};

export default SmallPromotionalCard;
