import React, { useEffect } from "react";
import "./address-page.scss";
import {
  useParams,
  Redirect
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../reducers";
import { Skeleton } from 'antd';
import LandingFooter from "../../components/landing-footer/LandingFooter";
import GetYourHeadOffice from "../../components/addresses-page-components/GetYourHeadOffice";
import StartDomiciliationBlock from "../../components/addresses-page-components/StartDomiciliationBlock";
import FAQBlock from "../../components/addresses-page-components/FAQBlock";
import AddressRawPictures from "../../components/address-page-components/AddressRawPictures";
import AddressDetailsCard from "../../components/address-page-components/AddressDetailsCard";
import orangeEllipse from "../../assets/png/Orange-Ellipse.png";
import { getAddress } from "../../actions/services-actions/actions";
import MapContainer from "../../tools/MapContainer";
import AddressReviews from "../../components/address-page-components/AddressReviews";
import AddressResponsible from "../../components/address-page-components/AddressResponsible";

const AddressPage = ({ history }: any) => {
  let { slug }: any = useParams();
  const dispatch = useDispatch();
  const address = useSelector((state: RootState) => state.serivcesReducer.address);
  const isLoadingAddress = useSelector((state: RootState) => state.serivcesReducer.isLoadingAddress);
  const useMountEffect = (fun: any) => useEffect(fun, [])

  const fetchAddress = async () => {
    await dispatch(getAddress(slug))
  }

  useMountEffect(() => {
    if (slug) {
      fetchAddress()
    }else{
      <Redirect to='/404' />
    }
  })

  useEffect(() => {
    if(address && address.id){
      document.title = address.page_title ?? `Adresse : ${address.full_location} | Domiciliation et création d'entreprise en Tunisie`;
      const description = document.querySelector('meta[name="description"]');
      if(description){
        description.setAttribute("content", address.page_description ?? address.full_location);
      }
      const canonical = document.querySelector('link[rel="canonical"]');
      if(canonical){
        canonical.setAttribute("href",  address.cononical_link ?? `https://domicilier.tn/address/${address.id}`);
      }
    }
  }, [address])
  return (
    <div className={"address-page"}>
      <Skeleton paragraph={{ rows: 8 }} loading={isLoadingAddress} active className={isLoadingAddress ? "max-height" : ""}>
        {address ?
          address.id ? (
            <>
              <AddressRawPictures />
              <AddressDetailsCard />
              <div className={"max-width-container"}>
                <div style={{ height: "100%" }}>
                  <MapContainer
                    address={address ? address : null}
                    height={"500"}
                  />
                </div>
              </div>
              <AddressResponsible/>
              <AddressReviews />
            </>
          ) : (
            null
          ) : (
            <Redirect to='/404' />
          )
        }
      </Skeleton>
      <div className={"head-and-faq-block"}>
        <GetYourHeadOffice />
        <img src={orangeEllipse} alt="ellipse" />
        <FAQBlock />
      </div>
      <StartDomiciliationBlock />
      <LandingFooter />
    </div>
  );
};

export default AddressPage;
