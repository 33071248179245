import React, { useEffect, useState } from "react";
import { Input, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const { Option } = Select;

interface OffersSearchBarProps {
  search: any,
}

const OffersSearchBar: React.FC<OffersSearchBarProps> = ({ search }) => {
  const [filter, setFilter] = useState('department');
  const [key, setKey]: [null | string, (key: null | string) => void] = useState<null | string>(null);
  const options = [
    <Option key={1} value={"department"}>Département</Option>,
    <Option key={2} value={"title"}>Poste</Option>,
  ];
  const changeFilter = (value: any) => {
    setFilter(value);
    setKey(null);
  }
  useEffect(() => {
    if (key && key.length > 0) {
      const values = { key: key, filter: filter }
      search(values)
    } else {
      setKey(null)
      search(null)
    }
  }, [key || filter])
  return (
    <div className={"search-bar"}>
      <Input
        placeholder="Rechercher..."
        onChange={(e) => setKey(e.target.value.trim())}
        value={key || ''}
        addonAfter={
          <div className="addon-after">
          <Select
            getPopupContainer={triggerNode => triggerNode.parentElement}
            onChange={changeFilter}
            value={filter}
          >
            {options}
          </Select>
          <div className={"search-icon"}>
            <SearchOutlined/>
          </div>
          </div>
          }
      />
    </div>
  );
};

export default OffersSearchBar;
