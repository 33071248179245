import React, { useEffect, useState } from "react";
import { Row, Col } from 'antd';
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import lightEllipse from  "../../assets/png/Light-Ellipse.png";

const AddressRawPictures = () => {
  const address = useSelector((state: RootState) => state.serivcesReducer.address);
  const [addressPictures, setAddressPictures] : any = useState([]);
  useEffect(() => {
    if (address) {
      setAddressPictures(address.pictures_list);
    }
  }, [address])
  const getPictureURL = (i: number) => {
    if (addressPictures.length > 0) {
      return addressPictures[i].url;
    }
    return ''
  }
  return (
    <>
      <div className={"address-raw-pictures"}>
        {addressPictures && addressPictures.length > 0 ? (
          <>
            <img src={lightEllipse} alt="Light Ellipse" className={"ellipse-right"} />
            {addressPictures && addressPictures.length > 2 ? (
              <Row className={"address-raw-pictures-wrapper"} gutter={[24, 24]} justify={"space-between"}>
                <Col xs={24} md={12}>
                  <div className={"picture-container"}>
                    <img src={getPictureURL(0)} alt="img" />
                  </div>
                </Col>
                <Col xs={24} md={12}>
                  <div className={"picture-container"}>
                    <img src={getPictureURL(1)} style={{ height: "50%", paddingBottom: "2rem" }} alt="img" />
                    <img src={getPictureURL(2)} style={{ height: "50%" }} alt="img" />
                  </div>
                </Col>
              </Row>
            ) : (
              <Row className={"address-raw-pictures-wrapper"} gutter={[24, 24]} justify={"center"}>
                <Col span={12}>

                  <div className={"picture-container"}>
                    <img src={getPictureURL(0)} alt="img" />
                  </div>
                </Col>

              </Row>
            )}
          </>
        ) : null}

      </div>
    </>
  );
};

export default AddressRawPictures;
