import TagManager from 'react-gtm-module'

const TAG_ID : any = process.env.REACT_APP_GA4_TAG;

const tagManagerArgs = {
    gtmId: TAG_ID
}

export const initiate = () => {
  TagManager.initialize(tagManagerArgs)
};