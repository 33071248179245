import React from "react";

const LinkedIn = () => {
    return (
<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.3335 7.72092H6.11127V17.1654H3.3335V7.72092ZM4.71405 6.60981H4.6985C3.86961 6.60981 3.3335 5.99203 3.3335 5.22036C3.3335 4.43203 3.88627 3.83203 4.73016 3.83203C5.57516 3.83203 6.09572 4.43203 6.11127 5.22036C6.11127 5.99148 5.57516 6.60981 4.71405 6.60981ZM16.6668 17.1654H13.8891V12.1104C13.8891 10.8893 13.2085 10.0559 12.1157 10.0559C11.2818 10.0559 10.8307 10.6181 10.6118 11.1615C10.5318 11.3559 10.5557 11.8937 10.5557 12.1654V17.1654H7.77794V7.72092H10.5557V9.17425C10.9563 8.55425 11.5835 7.72092 13.1879 7.72092C15.1757 7.72092 16.6663 8.97092 16.6663 11.762L16.6668 17.1654Z" fill="white"/>
</svg>
    );
};

export default LinkedIn;
