import {
    GET_ARTICLES_REQUEST,
    GET_ARTICLES_SUCCESS,
    GET_ARTICLES_FAILURE,
    GET_ARTICLE_REQUEST,
    GET_ARTICLE_SUCCESS,
    GET_ARTICLE_FAILURE,
  } from "./types";
  import Services from "./services";


  export function getArticles(params: any) {
    return async (dispatch: any) => {
      await dispatch({
        type: GET_ARTICLES_REQUEST
      });
      try {
        const response = await Services.getArticlesRequest(params);
        await dispatch({
          type: GET_ARTICLES_SUCCESS,
          payload: {
            articles: response.data.data,
            total: response.data.total,
            lastPage: response.data.last_page,
          },
        });
      } catch (e) {
        dispatch({
          type: GET_ARTICLES_FAILURE,
        });
      }
    };
  }

  export function getArticle(slug: string) {
    return async (dispatch: any) => {
      await dispatch({
        type: GET_ARTICLE_REQUEST
      });
      try {
        const response = await Services.getArticleRequest(slug);
        await dispatch({
          type: GET_ARTICLE_SUCCESS,
          payload: {
            article: response.data
          },
        });
      } catch (e) {
        dispatch({
          type: GET_ARTICLE_FAILURE,
          payload: {
            article: null
          },
        });
      }
    };
  }
