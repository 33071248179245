import { Reducer, AnyAction } from "redux";
import {
  OPEN_ADDRESSES_SIDER,
  CLOSE_ADDRESSES_SIDER,
  OPEN_SERVICES_SIDER,
  CLOSE_SERVICES_SIDER,
  OPEN_DASHBOARD_SIDER,
  CLOSE_DASHBOARD_SIDER,
  OPEN_APP_HEADER,
  CLOSE_APP_HEADER,
  OPEN_APP_SIDEBAR,
  CLOSE_APP_SIDEBAR,
  SET_PROMO_SEEN,
  SET_PROMO_NOTSEEN,
} from "../actions/ui-actions/types";

const INITIAL_STATE: any = {
  addresseSiderOpen: false,
  servicesSiderOpen: false,
  dashboardSiderOpened: false,
  isInApp: false,
  withSideBar: false,
  fullScreenMode: false,
  promoIsSeen: false,
};

export const uiReducer: Reducer<any, AnyAction> = (
  state: any = INITIAL_STATE,
  action
): boolean => {
  switch (action.type) {
    case OPEN_ADDRESSES_SIDER:
      return {
        ...state,
        addresseSiderOpen: true,
      };
    case CLOSE_ADDRESSES_SIDER:
      return {
        ...state,
        addresseSiderOpen: false,
      };
    case OPEN_SERVICES_SIDER:
      return {
        ...state,
        servicesSiderOpen: true,
      };
    case CLOSE_SERVICES_SIDER:
      return {
        ...state,
        servicesSiderOpen: false,
      };
    case OPEN_DASHBOARD_SIDER:
      return {
        ...state,
        dashboardSiderOpened: true,
      };
    case CLOSE_DASHBOARD_SIDER:
      return {
        ...state,
        dashboardSiderOpened: false,
      };
    case OPEN_APP_HEADER:
      return {
        ...state,
        isInApp: true,
      };
    case CLOSE_APP_HEADER:
      return {
        ...state,
        isInApp: false,
      };
    case OPEN_APP_SIDEBAR:
      return {
        ...state,
        withSideBar: true,
      };
    case CLOSE_APP_SIDEBAR:
      return {
        ...state,
        withSideBar: false,
      };
    case SET_PROMO_SEEN:
      return {
        ...state,
        promoIsSeen: true,
      };
    case SET_PROMO_NOTSEEN:
      return {
        ...state,
        promoIsSeen: false,
      };

    default:
      return state;
  }
};
