import React, { useEffect, useState } from "react";
import "./addresses-page.scss";
import LandingFooter from "../../components/landing-footer/LandingFooter";
import AddressesFirstBlock from "../../components/addresses-page-components/AddressesFirstBlock";
import AddressesList from "../../components/addresses-page-components/AddressesList";
import GetYourHeadOffice from "../../components/addresses-page-components/GetYourHeadOffice";
import FAQBlock from "../../components/addresses-page-components/FAQBlock";
import StartDomiciliationBlock from "../../components/addresses-page-components/StartDomiciliationBlock";
import orangeEllipse from "../../assets/png/Orange-Ellipse.png";

const AddressesPage = ({location}: any ) => {
  const [filter, setFilter] = useState(null);
  useEffect(() => {
    if (location.state && location.state.filter) {
      setFilter(location.state.filter)
    }
  }, [])
  useEffect(() => {
    document.title = `Nos Adresses | Domiciliation et création d'entreprise en Tunisie`;
    const description = document.querySelector('meta[name="description"]');
    if(description){
      description.setAttribute("content", "Domiciliation et création d'entreprise en Tunisie 100% en ligne, contrat de domiciliation, transfert de siège social au meilleur prix en Tunisie.");
    }
    const canonical = document.querySelector('link[rel="canonical"]');
    if(canonical){
      canonical.setAttribute("href", `https://domicilier.tn/addresses`);
    }
  }, [])
  return (
    <div className={"addresses-page"}>
      <AddressesFirstBlock />
      <AddressesList
        filter={filter}
      />
      <div className={"head-and-faq-block"}>
        <GetYourHeadOffice />
        <img src={orangeEllipse} alt="ellipse" />
        <FAQBlock />
      </div>
      <StartDomiciliationBlock />
      <LandingFooter />
    </div>
  );
};

export default AddressesPage;
