import React, { useEffect, useState } from "react";
import { Modal, Spin, Form, Input, Row, Col, Upload, message, Button } from 'antd';
import $ from "jquery";
import Saly from "../../assets/png/Billy.png";
import { RootState } from "../../reducers";
import {submitApplication} from "../../actions/services-actions/actions"
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";

const antIcon = <LoadingOutlined style={{ fontSize: 24, color: "#3240FF" }} spin />;

interface ApplyModelProps {
    visible: boolean,
    abort: any
}

const ApplyModel: React.FC<ApplyModelProps> = ({ visible, abort }: any) => {
    const dispatch = useDispatch();
    const isApplying = useSelector((state: RootState) => state.serivcesReducer.isApplying);
    const [orginalFile, setOrginalFile] = useState(null);
    const [form] = Form.useForm();
    const props = {
        name: 'file',
        action: process.env.REACT_APP_API_URL + "/upload-cv",
        headers: {
            Accept: "application/json",
            "Access-Control-Allow-Origin": "www.domicilier.tn"
        },
        onChange(info: any) {
            if (info.file.status === 'done') {
                setOrginalFile(info.file.name);
                // form.setFieldsValue({cv: response.filename})
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} n'a pas pu être téléchargé.`);
            }
        },
    };
    useEffect(() => {
        if (visible) {
            $(".app-container").css({ 'filter': 'brightness(50%) blur(2px)' });
        } else {
            $(".app-container").css({ 'filter': 'brightness(100%) blur(0)' });
        }
    }, [visible])
    const closeModal = () => {
        abort();
    }
    const onFinish = async (values: any) => {
        values.cv = values.cv.file.response.filename;
        await dispatch(submitApplication(values));
    }
    return (
        <Modal
            centered
            destroyOnClose
            visible={visible}
            onCancel={closeModal}
            style={{ top: 20 }}
            footer={<div></div>}
            wrapClassName={"apply-modal"}
            width={"90%"}
        >
            <div className={"apply-modal-content"}>
                <Row gutter={[24, 24]}>
                    <Col xs={24} md={12}>
                        <p>Je tente ma chance</p>
                        <h2>Envoyez <br /> votre candidature <br /> en 5 minutes ! </h2>
                        <Form
                            name="apply-form"
                            onFinish={onFinish}
                            className={"apply-form"}
                            form={form}
                        >
                            <Form.Item
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Tapez votre nom et prénom SVP!",
                                    },
                                ]}
                            >
                                <Input placeholder={"Nom et Prénom"} />
                            </Form.Item>
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        type: "email",
                                        message: "Tapez votre email SVP!",
                                    },
                                ]}
                            >
                                <Input placeholder={"Email"} />
                            </Form.Item>
                            <Form.Item
                                name="phone"
                                rules={[
                                    {
                                        required: true,
                                        message: "Tapez votre numéro de téléphone SVP!",
                                    },
                                ]}
                            >
                                <Input placeholder={"Téléphone"} />
                            </Form.Item>
                            <Form.Item
                                name="cv"
                                rules={[
                                    {
                                        required: true,
                                        message: "Téléchargez votre CV SVP!",
                                    },
                                ]}
                            >
                                <Upload {...props} className="upload-cv">
                                    <Button><span>{orginalFile ?? "Téléchargez votre CV"}</span> <UploadOutlined /></Button>
                                </Upload>
                            </Form.Item>
                            <button className={"apply-submit-btn blue-btn"} type="submit">
                                {isApplying ? (<Spin indicator={antIcon} />) : "Envoyer"}
                            </button>
                        </Form>
                    </Col>
                    <Col xs={24} md={12}>
                        <img src={Saly} className="img-billy" alt="img" />
                    </Col>
                </Row>
            </div>
        </Modal>
    );
}
export default ApplyModel;
