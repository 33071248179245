import React from "react";

const Instagram = () => {
    return (
<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<path d="M13.75 0.5H6.25C2.79875 0.5 0 3.29875 0 6.75V14.25C0 17.7013 2.79875 20.5 6.25 20.5H13.75C17.2013 20.5 20 17.7013 20 14.25V6.75C20 3.29875 17.2013 0.5 13.75 0.5ZM18.125 14.25C18.125 16.6625 16.1625 18.625 13.75 18.625H6.25C3.8375 18.625 1.875 16.6625 1.875 14.25V6.75C1.875 4.3375 3.8375 2.375 6.25 2.375H13.75C16.1625 2.375 18.125 4.3375 18.125 6.75V14.25Z" fill="white"/>
<path d="M10 5.5C7.23875 5.5 5 7.73875 5 10.5C5 13.2613 7.23875 15.5 10 15.5C12.7613 15.5 15 13.2613 15 10.5C15 7.73875 12.7613 5.5 10 5.5ZM10 13.625C8.2775 13.625 6.875 12.2225 6.875 10.5C6.875 8.77625 8.2775 7.375 10 7.375C11.7225 7.375 13.125 8.77625 13.125 10.5C13.125 12.2225 11.7225 13.625 10 13.625Z" fill="white"/>
<path d="M15.3752 5.78953C15.7432 5.78953 16.0415 5.49124 16.0415 5.12328C16.0415 4.75532 15.7432 4.45703 15.3752 4.45703C15.0073 4.45703 14.709 4.75532 14.709 5.12328C14.709 5.49124 15.0073 5.78953 15.3752 5.78953Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="20" height="20" fill="white" transform="translate(0 0.5)"/>
</clipPath>
</defs>
</svg>
    );
};

export default Instagram;
