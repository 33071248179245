import React from "react";
import { Row, Col } from "antd";
import FixingToolsSmallIcon from "../../svg-components/icons/FixingToolsSmallIcon";
import ServicesBuildingSmallIcon from "../../svg-components/icons/ServicesBuildingSmallIcon";
import GetYourHeadOfficeStep from "./GetYourHeadOfficeStep";

const GetYourHeadOffice = () => {
  return (
    <div className={"get-your-head-office-block"}>
      <div className={"max-width-container"}>
        <h1 className={"title"}>Obtenez votre siège social</h1>
        <h2 className={"blue-sub-title"}>en 3 clics !</h2>
        <Row gutter={[24, 24]} justify={"space-between"} className={"steps-wrapper"}>
          <Col md={8} xs={24}>
            <GetYourHeadOfficeStep
              title={"Choix de l’adresse. "}
              description={"Parce que le choix de l’adresse de votre siège social est déterminant, nous avons sélectionné pour vous des centres stratégiques à Tunis et dans toute la Tunisie."}
              btnvisible={true}
              btnText="Obtenir une Adresse"
              icon={<ServicesBuildingSmallIcon />}
            />
          </Col>
          <Col md={8} xs={24}>
            <GetYourHeadOfficeStep
              title={"Personnalisation des services."}
              description={"Nous mettons à votre disposition, une panoplie de services: entre gestion de courriers, permanence téléphoniques, réservation de salles de réunion, vous trouverez surement le service qui vous convient"}
              btnvisible={true}
              btnText="Découvrir nos services"
              icon={<FixingToolsSmallIcon />}
            />
          </Col>
          <Col md={8} xs={24}>
            <GetYourHeadOfficeStep
              title={"Téléchargement du contrat."}
              description={"Un justificatif d’identité, cliquez, signez ! Votre contrat est prêt ! téléchargez-le !"}
              btnvisible={false}
              btnText=""
              icon={<ServicesBuildingSmallIcon />}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default GetYourHeadOffice;
