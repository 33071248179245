import React from "react";
import domi from "../../assets/png/unsplash.png";
import lightEllipse from "../../assets/png/Light-Ellipse.png";
import { Row, Col } from 'antd';


const AboutFirstBlock = () => {
  return (
    <>
      <div className={"about-first-block"}>
        <img src={lightEllipse} alt="Light Ellipse" className={"ellipse-right"} />
        <div className={"max-width-container"}>
          <Row gutter={[12, 12]}>
            <Col xs={24} md={12}>
              <div className={"content"}>
                <h1><span>Domicilier.tn</span> <br />
                  est la première solution de domiciliation en ligne.  Un service unique et inédit en Tunisie et en Afrique avec un large choix de services sur-mesure.</h1>
              </div>
            </Col>
            <Col xs={24} md={12}>
              <div className={"image-container"}>
                <img src={domi} alt="img" />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default AboutFirstBlock;
