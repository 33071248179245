import ReactPixel from 'react-facebook-pixel';

const PIXEL_ID: any = process.env.REACT_APP_FACEBOOK_PIXEL_ID;

const options: any = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: !process.env.NODE_ENV || process.env.NODE_ENV === "development", // enable logs
};
export const initPIXEL = () => {
    // Enable debug mode on the local development environment
    ReactPixel.init(PIXEL_ID, options);
    ReactPixel.pageView();
};

export const trackingPagePIXEL = () => {
    ReactPixel.pageView(); // For tracking page view
};

export const eventPIXEL = (event: string, data: any) => {
    ReactPixel.track(event, data);
};