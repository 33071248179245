import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import firstImg from "../../assets/png/Rectangle.png";
import secondImg from "../../assets/png/Rectangle-2.png";

const HowItWorks = () => {
  const isLoggedIn = useSelector((state: RootState) => state.authReducer.isLoggedIn);

  return (
    <div className={"app-information-block-landing"} >
      {isLoggedIn ? null : (
        <div className={"links"}>
          <div className="max-width-container">
            <div className={"buttons-wrapper"}>
              <a href={process.env.REACT_APP_APP_URL + "/signup"} className={"white-btn-landing"}>Estimer ma domiciliation</a>
              <Link to={"/contact-me"} className={"white-btn-landing"}>je souhaite être contacter</Link>
            </div>
          </div>
        </div>
      )}

      <div className={"comment-ca-marche-block max-width-container"} id={"comment-ca-marche-section"}>
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 16]} justify={"space-between"}>
          <Col xs={24} md={12} lg={12}>
            <h2 className={"title"}>Domiciliation Tunisie en ligne, Comment ça marche ?</h2>
            <ol className="steps-list">
              <li>Choisissez une adresse prestigieuse.</li>
              <li>Choisissez les options qui vous conviennent pour la gestion de courrier.</li>
              <li>Signez en ligne et obtenez votre contrat de domiciliation en 3 minutes !</li>
            </ol>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <div className={"svg-container"}>
              <img src={secondImg} alt="Location salle de réunion à Tunis" className={"img-1"} />
              <img src={firstImg} alt="Créer entreprise sans louer des locaux" className={"img-2"} />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default HowItWorks;
