import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from 'antd';

const ServiceSecondBlock = () => {
  return (
    <>
    <div className={"service-second-block"} >
      <div className={"max-width-container"}>
        <h2>Une adresse, <strong>des privilèges !</strong></h2>
        <Row className={"assets-wrapper"} justify={"space-between"} >
          <Col xs={24} md={8}>
            <div className={"asset-item"}>
              <h3>Un service courrier personnalisé </h3>
              <p>Une adresse exclusivement professionnelle, pour préserver votre vie privée. Un service courrier personnalisé selon  la nature de votre activité.</p>
              <Link to={"/aide-domiciliation-tunisie "} className={"blue-btn"}>Pourquoi se domicilier ?</Link>
            </div>
          </Col>
          <Col xs={24} md={8}>
          <div className={"asset-item"}>
            <h3>Une expertise juridique pour vous assister</h3>
            <p>Votre entreprise créée en quelques clics, sans paperasse. Nos experts juridiques vous assistent dans votre démarche.</p>
            <a href={process.env.REACT_APP_APP_URL + "/signup"} className={"blue-btn"}>Je crée mon entreprise</a>
          </div>
          </Col>
          <Col xs={24} md={8}>
          <div className={"asset-item"}>
            <h3>Plus d’avantages pour votre entreprise</h3>
            <p>Réservez votre salle de réunion pour vos rendez-vous professionnelles. Bénéficiez d’une permanence téléphonique selon vos besoins.</p>
          </div>
          </Col>
        </Row>
      </div>
    </div>
    </>
  );
};

export default ServiceSecondBlock;
