import React, { useEffect, useState } from "react";
import { Row, Col, Pagination, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../reducers";
import ArticlesSearchBar from "./ArticlesSearchBar";
import { getArticles } from "../../actions/blog-actions/actions";
import { LoadingOutlined } from '@ant-design/icons';
import ArticleCard from "./ArticleCard";

const LoadingOutlinedIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;

const ArticlesList = () => {
  const dispatch = useDispatch();
  const articles = useSelector((state: RootState) => state.blogReducer.articles);
  const isLoadingArticles = useSelector((state: RootState) => state.blogReducer.isLoadingArticles);
  const lastPage = useSelector((state: RootState) => state.blogReducer.lastPage);
  const total = useSelector((state: RootState) => state.blogReducer.total);
  const [searchValues, setSearchValues] : any = useState(null);
  const [page, setPage] = useState(1);

  const fetchArticles = () => {
    let params: any = {};
    params.page = page;
    if (searchValues && searchValues.key) {
      params.key = searchValues.key;
      params.date = searchValues.date;
    }
    dispatch(getArticles(params));
  }

  const onChangePage = (next: number) => {
    setPage(next);
    const articlesList = document.getElementById('articles-list');
    if(articlesList){
      articlesList.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
    }
  }

  useEffect(() => {
    if (searchValues) {
      if (searchValues.key) {
        setPage(1);
      }
    }
    if (page <= lastPage) {
      fetchArticles();
    }
  }, [page, searchValues])
  return (
    <div className={"articles-list"} id={"articles-list"}>
      <ArticlesSearchBar search={(values:any) => setSearchValues(values)} />
      {isLoadingArticles ? (
        <div className="spinner-container" style={{ marginTop: "5%", minHeight: "500px" }}>
          <Spin indicator={LoadingOutlinedIcon} size="large" />
        </div>
      ) : (
        <>
          {articles && articles.length > 0 ? (
            <div>
              <Row className="articles-list-wrapper" gutter={[32, 32]} >
                {articles && articles.map((article: any, key: number) => (
                  <Col md={8} xs={24} key={key}>
                    <ArticleCard
                      article={article}
                    />
                  </Col>
                ))}
              </Row>
              <Pagination responsive hideOnSinglePage pageSize={6} current={page} onChange={onChangePage} total={total} />
            </div>
          ) : (
            <em style={{ color: "#bfbfbf", textAlign: "center", display: "block" }}>Aucun article</em>
          )}
        </>
      )}
    </div>
  );
};

export default ArticlesList;
