import React, { useEffect, useState } from "react";
import { Collapse, Skeleton } from "antd";
import PlusSmallIcon from "../../svg-components/icons/PlusSmallIcon";
import FAQSearchBar from "./FAQSearchBar";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
const { Panel } = Collapse;

const FAQBlock = () => {
  const faqs = useSelector((state: RootState) => state.serivcesReducer.faqs);
  const isLoadingFAQs = useSelector((state: RootState) => state.serivcesReducer.isLoadingFAQs);
  const [displayedFaqs, setdisplayedFaqs] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchValues, setSearchValues] : [any, (values: any) => void] = useState<any>(null);
  useEffect(() => {
    if (searchValues) {
      if (searchValues.key && searchValues.filter) {
        setIsSearching(true);
        const filtredFaqs = faqs.filter((faq: any, i: number) => faq.question.includes(searchValues.key));
        setdisplayedFaqs(filtredFaqs);
        setTimeout(() => {
          setIsSearching(false);
        }, 1000);
      }
    } else {
      if (faqs.length > 0) {
        setdisplayedFaqs(faqs);
      }
    }
  }, [searchValues, faqs])

  return (
    <div className={"faq-block"}>
      <div className={"max-width-container"}>
        <h2>Questions fréquentes ?</h2>
        <FAQSearchBar search={(values: any) => setSearchValues(values)} />
        <div className={"faq-wrapper"}>
          <Skeleton loading={isLoadingFAQs || isSearching} active >
            {displayedFaqs.length > 0 ? (
              <Collapse
                bordered={false}
                expandIcon={({ isActive }) => <PlusSmallIcon rotate={isActive ? 90 : 0} />}
                className="site-collapse-custom-collapse"
              >
                {displayedFaqs.map((faq: any, i: number) => (
                  <Panel header={<h3 className={"question"}>{faq.question}</h3>} key={faq.id} className="faq-item">
                    <p className={"response"} dangerouslySetInnerHTML={{ __html: faq.answer }}>
                    </p>
                  </Panel>
                ))}
              </Collapse>
            ) : (
              <em style={{ color: "#bfbfbf", textAlign: "center", display: "block" }}>Aucun résultat</em>
            )}
          </Skeleton>
        </div>
      </div>

    </div>
  );
};

export default FAQBlock;
