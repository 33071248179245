import {
    GET_ADDRESSES_REQUEST,
    GET_ADDRESSES_SUCCESS,
    GET_ADDRESSES_FAILURE,
    GET_ADDRESS_REQUEST,
    GET_ADDRESS_SUCCESS,
    GET_ADDRESS_FAILURE,
    GET_JOBS_REQUEST,
    GET_JOBS_SUCCESS,
    GET_JOBS_FAILURE,
    GET_JOB_REQUEST,
    GET_JOB_SUCCESS,
    GET_JOB_FAILURE,
    GET_PROMOTIONS_REQUEST,
    GET_PROMOTIONS_SUCCESS,
    GET_PROMOTIONS_FAILURE,
    GET_ADDRESS_REVIEWS_REQUEST,
    GET_ADDRESS_REVIEWS_SUCCESS,
    GET_ADDRESS_REVIEWS_FAILURE,
    GET_REVIEWS_REQUEST,
    GET_REVIEWS_SUCCESS,
    GET_REVIEWS_FAILURE,
    GET_FAQS_REQUEST,
    GET_FAQS_SUCCESS,
    GET_FAQS_FAILURE,
    GET_DEPARTMENTS_REQUEST,
    GET_DEPARTMENTS_SUCCESS,
    GET_DEPARTMENTS_FAILURE,
    APPLY_REQUEST,
    APPLY_SUCCESS,
    APPLY_FAILURE,
  } from "./types";
  import Services from "./services";


  export function getAddresses(filter : string | null = null) {
    return async (dispatch: any) => {
      await dispatch({
        type: GET_ADDRESSES_REQUEST
      });
      try {
        const response = await Services.getAddresses(filter);
        await dispatch({
          type: GET_ADDRESSES_SUCCESS,
          payload: {
            addresses: response.data
          },
        });
      } catch (e) {
        dispatch({
          type: GET_ADDRESSES_FAILURE,
          payload: {
            addresses: []
          },
        });
      }
    };
  }

  export function getAddress(slug: string) {
    return async (dispatch: any) => {
      await dispatch({
        type: GET_ADDRESS_REQUEST
      });
      try {
        const response = await Services.getAddressRequest(slug);
        await dispatch({
          type: GET_ADDRESS_SUCCESS,
          payload: {
            address: response.data
          },
        });
      } catch (e) {
        dispatch({
          type: GET_ADDRESS_FAILURE,
          payload: {
            address: null
          },
        });
      }
    };
  }

  export function getDepartments() {
    return async (dispatch: any) => {
      await dispatch({
        type: GET_DEPARTMENTS_REQUEST
      });
      try {
        const response = await Services.getDepartmentsRequest();

        await dispatch({
          type: GET_DEPARTMENTS_SUCCESS,
          payload: {
            departments: response.data,
          },
        });
      } catch (e) {
        dispatch({
          type: GET_DEPARTMENTS_FAILURE,
          payload: {
            departments: [],
          },
        });
      }
    };
  }

  export function getJobOffers(params: any) {
    return async (dispatch: any) => {
      await dispatch({
        type: GET_JOBS_REQUEST
      });
      try {
        const response = await Services.getJobOffersRequest(params);
        await dispatch({
          type: GET_JOBS_SUCCESS,
          payload: {
            jobOffers: response.data.data,
            total: response.data.total,
            lastPage: response.data.last_page
          },
        });
      } catch (e) {
        dispatch({
          type: GET_JOBS_FAILURE,
          payload: {
            payload: {
              jobOffers: [],
              total: 0,
              lastPage: 1
            },
          },
        });
      }
    };
  }

  export function getJobOffer(slug: string) {
    return async (dispatch: any) => {
      await dispatch({
        type: GET_JOB_REQUEST
      });
      try {
        const response = await Services.getJobOfferRequest(slug);
        await dispatch({
          type: GET_JOB_SUCCESS,
          payload: {
            jobOffer: response.data
          },
        });
      } catch (e) {
        dispatch({
          type: GET_JOB_FAILURE,
          payload: {
            jobOffer: null
          },
        });
      }
    };
  }

  export function getPromotions() {
    return async (dispatch: any) => {
      await dispatch({
        type: GET_PROMOTIONS_REQUEST
      });
      try {
        const response = await Services.getPromotionsRequest();
        await dispatch({
          type: GET_PROMOTIONS_SUCCESS,
          payload: {
            promotions: response.data
          },
        });
      } catch (e) {
        dispatch({
          type: GET_PROMOTIONS_FAILURE,
        });
      }
    };
  }

  export function getAddressReviews(id: number) {
    return async (dispatch: any) => {
      await dispatch({
        type: GET_ADDRESS_REVIEWS_REQUEST
      });
      try {
        const response = await Services.getAddressReviewsRequest(id);
        await dispatch({
          type: GET_ADDRESS_REVIEWS_SUCCESS,
          payload: {
            reviews: response.data
          },
        });
      } catch (e) {
        dispatch({
          type: GET_ADDRESS_REVIEWS_FAILURE,
        });
      }
    };
  }

  export function getHomeReviews() {
    return async (dispatch: any) => {
      await dispatch({
        type: GET_REVIEWS_REQUEST
      });
      try {
        const response = await Services.getHomeReviewsRequest();
        await dispatch({
          type: GET_REVIEWS_SUCCESS,
          payload: {
            reviews: response.data
          },
        });
      } catch (e) {
        dispatch({
          type: GET_REVIEWS_FAILURE,
        });
      }
    };
  }

  export function getFAQs() {
    return async (dispatch: any) => {
      await dispatch({
        type: GET_FAQS_REQUEST
      });
      try {
        const response = await Services.getFAQsRequest();
        await dispatch({
          type: GET_FAQS_SUCCESS,
          payload: {
            faqs: response.data
          },
        });
      } catch (e) {
        dispatch({
          type: GET_FAQS_FAILURE,
        });
      }
    };
  }

  
  export function submitApplication(offerId: number | null) {
    return async (dispatch: any) => {
      await dispatch({
        type: APPLY_REQUEST
      });
      try {
        await Services.applyRequest(offerId);
        await dispatch({
          type: APPLY_SUCCESS,
          payload: {
            applied: true
          },
        });
      } catch (e) {
        dispatch({
          type: APPLY_FAILURE,
          payload: {
            applied: false
          },
        });
      }
    };
  }

 