import React from "react";

const BenefitsAuthSmallIcon = () => {
  return (
<svg width="38" height="45" viewBox="0 0 38 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M37.7633 36.3383C37.7608 33.8961 36.7208 32.8635 34.2663 32.8611C31.5432 32.8586 28.8176 32.8611 26.0058 32.8611C26.0058 31.8803 25.9442 31.0251 26.0181 30.1823C26.1808 28.3537 27.6668 26.9983 29.4411 26.9762C31.3682 26.9565 32.4624 27.9323 32.7852 29.963C32.7902 29.9925 32.7951 30.0246 32.8 30.0566C32.8 30.0591 32.8 30.0616 32.8 30.064C32.8 30.1404 32.8099 30.2143 32.8247 30.2858C32.8247 30.2883 32.8247 30.2932 32.8247 30.2957H32.8271C32.9331 30.7516 33.3397 31.0917 33.8277 31.0917C34.3477 31.0917 34.7715 30.7072 34.843 30.207C34.8479 30.1724 34.8529 30.1379 34.8529 30.101C34.8529 30.0887 34.8578 30.0763 34.8578 30.064C34.8578 30.0591 34.8553 30.0517 34.8553 30.0468C34.8578 29.8127 34.7986 29.549 34.7543 29.3198C34.1924 26.5646 31.6886 24.7755 28.7757 25.0293C26.1413 25.2585 24.0639 27.6021 24.0392 30.3795C24.0318 31.1533 24.0368 31.9246 24.0368 32.7896C21.4861 33.0286 20.6064 33.9848 20.6039 36.3974C20.6039 37.9919 20.5891 39.5839 20.6088 41.1783C20.631 42.9083 21.804 44.1208 23.5143 44.1331C27.2725 44.1553 31.0306 44.1528 34.7888 44.1331C36.573 44.1257 37.7436 42.9231 37.7633 41.1192C37.7781 39.5247 37.7657 37.9278 37.7633 36.3383ZM35.7992 40.2887C35.777 41.6046 35.2422 42.1616 33.941 42.169C30.7546 42.1887 27.5707 42.1912 24.3842 42.169C23.1397 42.1591 22.5951 41.6046 22.568 40.3725C22.5409 39.1501 22.5458 37.9229 22.5655 36.6981C22.5852 35.3772 23.1102 34.8424 24.4212 34.8227C26.0156 34.8005 27.6076 34.8178 29.1996 34.8178C30.7916 34.8178 32.386 34.8005 33.978 34.8227C35.225 34.8424 35.7696 35.3797 35.7992 36.6143C35.8263 37.8391 35.8213 39.0639 35.7992 40.2887Z" fill="white"/>
<path d="M15.6998 30.8995C14.5366 30.897 13.7111 31.7447 13.7382 32.9129C13.7628 34.0169 14.581 34.8153 15.6899 34.8153C16.8433 34.8178 17.6812 33.9577 17.654 32.7995C17.6294 31.7004 16.8088 30.9019 15.6998 30.8995Z" fill="white"/>
<path d="M15.6949 40.7076C15.6949 40.7052 15.6949 40.7052 15.6949 40.7076C12.8978 40.715 9.47728 40.7249 6.68023 40.7002C3.85114 40.6756 1.97822 38.8248 1.97083 36.0105C1.95111 26.2024 1.95111 16.3967 1.97329 6.58848C1.97822 3.84811 3.86346 1.98012 6.62355 1.9678C12.5085 1.94069 18.3909 1.94069 24.2758 1.9678C27.031 1.98012 28.8817 3.83825 28.9334 6.58602C28.9655 8.385 28.9384 10.1815 28.9384 11.9805C28.9408 14.716 28.9261 17.3553 28.9482 20.0907C28.9482 20.0932 28.9482 20.0957 28.9482 20.0981C28.9482 20.6329 29.382 21.0691 29.9192 21.0691C30.4564 21.0691 30.8902 20.6354 30.8902 20.0981C30.9074 15.3912 30.9049 10.7828 30.9 6.07836C30.8951 2.48778 28.4135 0.00862528 24.8229 0.00369655C18.5708 -0.00123218 12.3187 -0.00123218 6.0666 0.00369655C2.47602 0.00616091 0.00919234 2.48531 0.00672797 6.09314C0.00179924 11.1599 0.00426361 16.2266 0.00426361 21.2933C0.00426361 26.4833 -0.00805822 31.6733 0.00919234 36.8607C0.0190498 40.0644 2.56227 42.6372 5.74377 42.6569C8.85133 42.6791 12.5848 42.6643 15.6924 42.6643C15.6949 42.6643 15.6949 42.6643 15.6973 42.6643C16.2395 42.6643 16.6782 42.2257 16.6782 41.6835C16.6757 41.1463 16.237 40.7076 15.6949 40.7076Z" fill="white"/>
<path d="M29.1823 36.3999C28.5465 36.3999 28.029 36.9149 28.029 37.5532V39.4434C28.029 40.0792 28.5441 40.5967 29.1823 40.5967C29.8206 40.5967 30.3357 40.0816 30.3357 39.4434V37.5532C30.3357 36.9174 29.8206 36.3999 29.1823 36.3999Z" fill="white"/>
</svg>


  );
};

export default BenefitsAuthSmallIcon;
