import React from "react";
import domi from "../../assets/png/Saly-11.png";
import lightEllipse from "../../assets/png/Light-Ellipse.png";


const HelpFirstBlock = () => {
  return (
    <>
      <div className={"help-first-block"} style={{ backgroundImage: `url('${domi}')` }}>
        <img src={lightEllipse} alt="Light Ellipse" className={"ellipse-right"} />
        <div className={"max-width-container"}>
          <div className={"content"}>
            <h1><strong>Domicilier</strong> <br /> votre entreprise <br /> <strong>à Tunis</strong></h1>
            <p>Vous souhaitez obtenir de l'aide ? Notre FAQ est le moyen le plus rapide pour obtenir de l'aide. Ici, vous trouverez des réponses sur de nombreux sujets. <br /> Vous pouvez toujours nous contacter ici, notre équipe sera ravie de vous répondre !</p>
          </div>
          <div className={"mobile-image-container"}>
            <img src={domi} alt="img" />
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpFirstBlock;
