import React, { useEffect } from 'react';
import "./not-found-page.scss";
import { Link } from 'react-router-dom';
import noFoundImage from '../../assets/png/404_Illustrations.png';

const NotFoundPage = ({history} : any) => {
  useEffect(() => {
    document.title = `Page introuvable | Domicilier`
  })
  return (
    <div className="not-found-page">
      <div className="center">
        <img src={noFoundImage} alt="not found" />
        <div className="title">Erreur 404</div>
        <div className="text">La page que vous recherchez n'est pas disponible ou n'appartient pas à ce site !</div>
        <Link className="button" to="/">Retour à l'acceuil</Link>
      </div>
      <div className="objects-styling-absolute-container">
        <div className="circle-bottom-styled" />
        <div className="circle-blue-right-top-styled" />
      </div>
    </div>
  );
};

export default NotFoundPage;
