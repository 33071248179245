/*
@
 This file contains the requests ( services )
@
*/

import axiosInstance from "../../config/axios-instance";

function getArticlesRequest(params: any) {
  return axiosInstance({
    method: "get",
    url: `services/articles`,
    params: params
  });
}

function getArticleRequest(slug: string) {
  return axiosInstance({
    method: "get",
    url: `services/articles/${slug}`,
  });
}

const Services = {
    getArticlesRequest,
    getArticleRequest,
};

export default Services;
