import React, { useEffect, useState } from "react";
import { RootState } from "../../reducers";
import { useSelector } from "react-redux";
import { Popover, Row, Col, Button } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import ApplyModel from "../../components/job-offers-components/ApplyModel";
import JobAppliedModel from "../../components/job-offers-components/JobAppliedModel";
import moment from 'moment';
import 'moment/locale/fr'
import Timer from "../../svg-components/icons/Timer";
import Place from "../../svg-components/icons/Place";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { SocialIcon } from 'react-social-icons';
import domi from "../../assets/png/Saly-13.png";
import lightEllipse from "../../assets/png/Light-Ellipse.png";
moment.locale('fr')

const JobOfferFirstBlock = () => {
  const jobOffer = useSelector((state: RootState) => state.serivcesReducer.jobOffer);
  const applied = useSelector((state: RootState) => state.serivcesReducer.applied);
  const [applicationModal, setApplicationModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  useEffect(() => {
    if (applied) {
      setApplicationModal(false);
      setSuccessModal(true);
    }
  }, [applied])
  const [isCopied, setIsCopied] = useState(false);
  const copyToClipboard = () => {
    navigator.clipboard.writeText(process.env.REACT_APP_SITE_URL + '/' + window.location.pathname).then(function () {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    }, function (err) {
      setIsCopied(false);
    });
  }
  const content = (
    <div className={"share-buttons"}>
      <Popover placement="top" content={isCopied ? "Lien copié!" : null} visible={isCopied} trigger="click" destroyTooltipOnHide>
        <Button
          onClick={copyToClipboard}
          className={"copy-to-clipboard-btn"}
          title="Copier le lien"
        >
          <CopyOutlined />
        </Button>
      </Popover>
      <LinkedinShareButton url={process.env.REACT_APP_SITE_URL + '/' + window.location.pathname}
        children={<SocialIcon network="linkedin" title="LinkedIn" />}
      />
      <FacebookShareButton url={process.env.REACT_APP_SITE_URL + '/' + window.location.pathname}
        children={<SocialIcon network="facebook" title="Facebook" />}
      />
      <TwitterShareButton url={process.env.REACT_APP_SITE_URL + '/' + window.location.pathname}
        children={<SocialIcon network="twitter" title="Twitter" />}
      />
      <WhatsappShareButton url={process.env.REACT_APP_SITE_URL + '/' + window.location.pathname}
        children={<SocialIcon network="whatsapp" title="WhatsApp" />}
      />
    </div>
  );
  return (
    <>
      <div className={"job-offer-first-block"} >
        <img src={lightEllipse} alt="Light Ellipse" className={"ellipse-right"} />
        <div className={"max-width-container"}>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 16]} justify={"space-between"} >
            <Col xs={24} md={12} lg={12}>
              <div className={"content"}>
                {jobOffer ? (
                  <>
                    <div>
                      <div className={"job-offer-card-header"} >
                        <h1 className={"job-offer-card-title"}>
                          {jobOffer.title}
                        </h1>
                        <span>Publiée il y a {moment(jobOffer.created_at).fromNow(true)}</span>
                      </div>
                      <div className={"job-offer-card-preview"}>
                        {jobOffer.preview}
                      </div>
                    </div>
                    <div className={"job-offer-card-address"}>
                      <Place />
                      <p>{`${jobOffer.address.address_line_1}, ${jobOffer.address.city} ${jobOffer.address.zip_code}, ${jobOffer.address.state}`}</p>
                    </div>
                    <div className={"job-offer-card-type"}>
                      <Timer />
                      <p>{jobOffer.type === "parttime" ? "Temps partiel" : "Temps plein"}</p>
                    </div>
                    <div className={"job-offer-card-footer"}>
                      <Button onClick={() => setApplicationModal(true)} className="blue-btn">Postuler</Button>
                      <Popover placement="bottom" title={"Partager"} content={content} trigger="click" className={"share-buttons"}>
                        <Button className={"white-btn"}>Partager</Button>
                      </Popover>
                    </div>
                  </>
                ) : null}
              </div>
            </Col>
            <Col xs={24} md={10} lg={10}>
              <div className={"svg-container"}>
                <img src={domi} alt="img" />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <ApplyModel
        visible={applicationModal}
        abort={() => setApplicationModal(false)}
      />
      <JobAppliedModel
        visible={successModal}
        abort={() => setSuccessModal(false)}
      />
    </>
  );
};

export default JobOfferFirstBlock;
