import React from "react";
import ArticlesList from "../../components/articles-components/ArticlesList";

const ArticlesBlock = () => {

  return (
    <>
    <div className={"articles-block"} >
      <div className={"max-width-container"}>
        <h2>Blog</h2>
        <ArticlesList />
      </div>
    </div>
    </>
  );
};

export default ArticlesBlock;
