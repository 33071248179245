import React from "react";
import { Row, Col } from 'antd';
import domi from "../../../assets/png/annie-spratt.png";

const HowToBlock = () => {
  return (
    <>
    <div className={"how-to-block"} >
      <div className={"max-width-container"}>
        <h2>Comment <strong>se domicilier ?</strong></h2>
        <Row className={"content"} justify={"space-between"} >
          <Col xs={24} md={14}>
            <div className={"step"}>
              <div className={"step-number"}>
                .01
              </div>
              <div className={"body"}>
                <h3>Remplissez notre formulaire</h3>
                <p>Choisissez l’adresse de domiciliation et l’option courrier qui vous convient le mieux. Vous pourrez également ajouter d’autres services mis à votre disposition (gestion des formalités juridiques, permanence téléphonique…)</p>
              </div>
            </div>
            <div className={"step"}>
              <div className={"step-number"}>
                .02
              </div>
              <div className={"body"}>
                <h3>Effectuez le paiement en ligne</h3>
                <p>Finalisez votre domiciliation en effectuant le paiement sur notre plateforme sécurisée.</p>
              </div>
            </div>
            <div className={"step"}>
              <div className={"step-number"}>
                .03
              </div>
              <div className={"body"}>
                <h3>Complétez votre dossier</h3>
                <p>Complétez votre dossier en joignant les pièces justificatives demandées.</p>
              </div>
            </div>
            <div className={"step"}>
              <div className={"step-number"}>
                .04
              </div>
              <div className={"body"}>
                <h3>Recevez votre contrat de domiciliation</h3>
                <p>Après une vérification de votre dossier, vous recevrez votre contrat de domiciliation dans un délai de 48h.</p>
              </div>
            </div>
          </Col>
          <Col xs={24} md={10}>
            <img src={domi} alt="img"/>
          </Col>
        </Row>
      </div>
    </div>
    </>
  );
};

export default HowToBlock;
