import React from "react";

const Timer = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.0703 1.01172H9.07031V3.01172H15.0703V1.01172ZM11.0703 14.0117H13.0703V8.01172H11.0703V14.0117ZM19.1003 7.39172L20.5203 5.97172C20.0903 5.46172 19.6203 4.98172 19.1103 4.56172L17.6903 5.98172C16.1403 4.74172 14.1903 4.00172 12.0703 4.00172C7.10031 4.00172 3.07031 8.03172 3.07031 13.0017C3.07031 17.9717 7.09031 22.0017 12.0703 22.0017C17.0503 22.0017 21.0703 17.9717 21.0703 13.0017C21.0703 10.8917 20.3303 8.94172 19.1003 7.39172ZM12.0703 20.0117C8.20031 20.0117 5.07031 16.8817 5.07031 13.0117C5.07031 9.14172 8.20031 6.01172 12.0703 6.01172C15.9403 6.01172 19.0703 9.14172 19.0703 13.0117C19.0703 16.8817 15.9403 20.0117 12.0703 20.0117Z" fill="#2A3F58" />
    </svg>
  );
};

export default Timer;
