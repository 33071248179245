import React, { useEffect } from "react";
import { Rate , Row, Col, Progress, Avatar, Skeleton } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../reducers";
import {getAddressReviews} from "../../actions/services-actions/actions";
import { UserOutlined } from '@ant-design/icons';

const AddressReviews = () => {
  const dispatch = useDispatch();
  const address = useSelector((state: RootState) => state.serivcesReducer.address);
  const reviews = useSelector((state: RootState) => state.serivcesReducer.reviews);
  const isLoadingReviews = useSelector((state: RootState) => state.serivcesReducer.isLoadingReviews);

  useEffect(() => {
    if(address){
      dispatch(getAddressReviews(address.id))
    }
  }, [address])
  const getStats = () => {
    let stats = [];
    for (let index = 5; index > 0; index--) {
      stats.push(
      <div style={{display: "flex", margin: "1rem auto"}}>
        <span style={{paddingRight: "1rem"}}>{index}</span>
        <Progress percent={reviews.filter((review: any) => review.rating == index).length / reviews.length * 100} showInfo={false}/>
      </div>
      )
    }
    return stats
  }
  const getAverage = () => {
    let sum = 0;
    reviews.map((review: any, i: number) => {
      sum += review.rating;
    })

    return Math.round(sum / reviews.length)
  }
  return (
    <div className={"address-reviews-wrapper"}>
      <div className={"max-width-container"}>
        <Skeleton loading={isLoadingReviews} active>
          <h2>Avis clients</h2>
          {reviews.length > 0 ? (
            <>
              <Row gutter={[24, 24]}>
                <Col xs={24} md={12}>
                  <h3>Avis global</h3>
                  <div className={"average-rating"}>
                    <span>{getAverage()}</span>
                    <Rate disabled defaultValue={getAverage()} />
                  </div>
                </Col>
                <Col xs={24} md={12}>
                  {getStats()}
                </Col>
              </Row>
              <Row gutter={[24, 24]}>
                {reviews.map((review: any, key: number) => (
                  <Col xs={24} md={12} key={key}>
                    <Row gutter={[24, 24]} align="middle" className={"reviews-wrapper"}>
                      <Col xs={8} md={4} className={"avatar-container"}><Avatar size={50} icon={<UserOutlined/>} src={review.author_avatar ? process.env.REACT_APP_STORAGE_URL + review.author_avatar : null}/></Col>
                      <Col xs={24} md={20}>
                        <strong className={"author-name"}>{ window.capitalize(review.author_name || '') + ' ' + window.capitalize(review.author_surname || '')}</strong>
                        <p>{review.content || null}</p>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </>
          ) : (
            <em style={{margin: "5% auto",color: "#bfbfbf", textAlign: "center", display: "block"}}>Aucun avis</em>
          )}
        </Skeleton>
      </div>
    </div>
  );
};

export default AddressReviews;
