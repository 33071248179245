import React from "react";
import orangeEllipse from "../../assets/png/Orange-Ellipse.png";

const TermsOfUseBlock = () => {
  return (
    <>
      <div className={"terms-conditions-block"} >
      <img src={orangeEllipse} alt="ellipse" className={"left-ellipse"}/>
        <div className="max-width-container">
          <h2>Conditions générales de vente et d’utilisation</h2>
          <p>Le présent document prévoit en tant que CGU les modalités et conditions d’accès au site Internet accessible à l’adresse <a href="https://www.domicilier.tn">www.domicilier.tn</a> par tout Utilisateur ayant souscrit des services auprès de domicilier.tn dans le cadre de l’activité de domiciliation et de location de bureaux équipés.</p>
          <h3>Sommaire</h3>
          <ul >
            <li>Préambule </li>
            <li>Art. 2 : Utilisation du Service</li>
            <li>Art. 3 : Information sur l’identifiant et la connexion</li>
            <li>Art. 4 : Protection des données personnelles </li>
            <li>Art. 5 : Références commerciales</li>
            <li>Art. 6 : Maintenance</li>
            <li>Art. 7 : Contact</li>
          </ul>
          <p>Conditions Générales d’Utilisation (CGU) à destination des Utilisateurs</p>
          <div className={"represent-wrapper"}>
              <p>DOMICILIER.TN</p>
              <p>ADRESSE</p>
              <p>Représentant légal : Monsieur AYMEN MOKADEM</p>
          </div>
          <p>Le présent document constitue les Conditions Générales d’Utilisation (CGU) du service. Les CGU étant susceptible d’être modifiées, nous vous invitons à vous y référer régulièrement.</p>
          <h3>Préambule</h3>
          <p>Le présent document constitue les Conditions Générales d’Utilisation (CGU) du service. Les CGU étant susceptible d’être modifiées, nous vous invitons à vous y référer régulièrement. Préambule Le présent document prévoit en tant que CGU les modalités et conditions d’accès au site Internet accessible à l’adresse domicilier.tn par tout Utilisateur ayant souscrit des services auprès de domicilier.tn dans le cadre de l’activité de domiciliation et de location de bureaux équipés. Il est précisé que les présentes conditions d’utilisation du Service sont subordonnées au respect des mentions et stipulations contractuelles conclues entre la Société et l’utilisateur dans le cadre de l’édition en ligne et de la signature (contractualisation numérique) du contrat de domiciliation et de ses annexes. L’acceptation des CGU du Service est matérialisée par sélection de la rubrique « j’ai bien pris connaissance des informations et conditions qui précèdent » (en double cliquant sur la case). L’acceptation est de ce fait réputée acceptée sans réserve. Toute modification des CGU est notifiée électroniquement à l’utilisateur, les CGU seront applicables dès acceptation de celles-ci. En cas de refus des CGU par l’utilisateur, domicilier.tn pourra mettre un terme à la relation contractuelle liant la société et l’utilisateur. L’invalidité d’une disposition des CGU n’affecte pas la validité des autres dispositions contractuelles.</p>
          <div className={"clause-wrapper"}>
            <h3>Article 1 : Mode de fonctionnement du Service et Obligation de l’utilisateur</h3>
            <p>
            L’Utilisateur bénéficie d’un espace personnel permettant de prendre connaissance des informations mises à sa disposition. L’Utilisateur s’engage à communiquer les informations nécessaires à la mise en place et au bon fonctionnement de son interface personnelle (pièces d’identité et justificatifs administratifs, adresse électronique, RIB et autorisation de prélèvement, personnes habilitées à récupérer le courrier…). Les demandes de communication d’informations sont adressées par la Société à l’Utilisateur lequel y répondra en remplissant un questionnaire lors de l’inscription aux services. Faute de communication des informations idoines, la Société ne pourra être tenue responsable des dysfonctionnements de l’accès à l’interface et/ou à l’espace personnel de l’utilisateur.
            </p>
          </div>
          <div className={"clause-wrapper"}>
            <h3>Art. 2 : Utilisation du Service</h3>
            <p>
            L’utilisateur confirme posséder les connaissances techniques nécessaires à assurer une administration correcte du Service, particulièrement en ce qui concerne la manipulation de ses données. L’Utilisateur est l’entier responsable de l’utilisation du Service. La responsabilité de la Société ne saurait être engagée du fait d’un défaut de fonctionnement du Service consécutif à une mauvaise utilisation du Service par l’Utilisateur. L’Utilisateur s’engage à utiliser ce Service en bonne intelligence. En cas d’utilisation anormale du Service, la Société se réserve le droit de résilier la commande.
            </p>
          </div>
          <div className={"clause-wrapper"}>
            <h3>Art. 3 : Information sur l’identifiant et la connexion</h3>
            <p>
            L’accès à l’espace personnel de l’Utilisateur s’opère au moyen de l’ouverture d’un compte personnel au moyen d’un identifiant et d’un mot de passe que l’utilisateur aura lui-même déterminé. Ce mot de passe pourra être modifié dans l’interface intitulée « Paramètres». La combinaison identifiant/mot de passe est strictement personnelle. Il appartient à l’Utilisateur d’en garantir la confidentialité. En cas de perte et/ou d’oubli du mot de passe, le titulaire du compte pourra en demander la réinitialisation depuis son espace personnel. L’Utilisateur s’engage également à ne communiquer ces informations d’identification à aucune autre personne, ni à les prêter, donner, vendre et, de manière générale, mettre le compte et/ou le mot de passe à la disposition d’un tiers, pour quelque raison que ce soit. A défaut la responsabilité de la Société ne pourra être aucunement engagée en cas d’utilisation frauduleuse de ces informations. En cas d’utilisation frauduleuse des Identifiants de connexion du fait d’une faute ou négligence imputable à l’utilisateur, ou à l’un des salariés ou toute personne agissant sous son contrôle ou son autorité hiérarchique, l’Utilisateur sera responsable de toute perte ou détérioration de données quelles qu’elles soient, et plus généralement de tout dommage subi en raison d’une utilisation du service non conforme aux règles édictées dans les présentes conditions générales.
            </p>
          </div>
          <div className={"clause-wrapper"}>
            <h3>Art. 4 : Protection des données personnelles</h3>
            <p>
            Les informations à caractère personnel collectées et traitées dans le cadre de l’utilisation du service sont couvertes par les engagements de domicilier.tn de respecter la réglementation sur la protection des données à caractère personnel résultant du Règlement sur la protection des données à caractère personnel et de la loi Informatique et libertés modifiées. Cette règlementation reconnait également des droits aux Utilisateurs.
            </p>
          </div>
          <div className={"clause-wrapper"}>
            <h3>Art. 5 : Références commerciales</h3>
            <p>
            L’Utilisateur accepte de figurer sur la liste des références client de la Société, (raison sociale et logos correspondants) à titre de référence commerciale. Afin d’améliorer la pertinence de ses services, la Société enregistre des informations statistiques et non personnelles relatives à l’utilisation du Service (fréquence d’utilisation, quantité et type de données saisies).
            </p>
          </div>
          <div className={"clause-wrapper"}>
            <h3>Art. 6 : Maintenance</h3>
            <p>
            Le fonctionnement du Service peut être interrompu temporairement pour procéder à des opérations de maintenance ou de mises à jour.
            </p>
          </div>
          <div className={"clause-wrapper"}>
            <h3>Art. 7 : Contact</h3>
            <p>
            Pour toute question, veuillez joindre domicilier.tn à l’adresse suivante de manière électronique ou postale : ADRESSE ELECTRONIQUE ADRESSE POSTALE
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsOfUseBlock;
