import React from "react";
import orangeEllipse from "../../assets/png/Orange-Ellipse.png";

const TermsConditionsBlock = () => {
  return (
    <>
      <div className={"terms-conditions-block"} >
      <img src={orangeEllipse} alt="ellipse" className={"left-ellipse"}/>
        <div className="max-width-container">
          <h2>Mentions légales</h2>
          <div className={"clause-wrapper"}>
            <h3>Clause n° 1 : Objet</h3>
            <p>
              Les conditions générales de vente décrites ci-après détaillent les droits et obligations de la société <strong>Domicilier.tn</strong> et de son client dans le cadre de la vente des marchandises suivantes : … (le vendeur doit recenser les marchandises soumises aux CGV). Toute prestation accomplie par la société <strong>Domicilier.tn</strong> implique donc l’adhésion sans réserve de l’acheteur aux présentes conditions générales de vente.
            </p>
          </div>
          <div className={"clause-wrapper"}>
            <h3>Clause n° 2 : Prix</h3>
            <p>
              Les prix des marchandises vendues sont ceux en vigueur au jour de la prise de commande.Ils sont libellés en euros et calculés hors taxes.Par voie de conséquence, ils seront majorés du taux de TVA et des frais de transport applicables au jour de la commande.
              La société <strong>Domicilier.tn</strong> s’accorde le droit de modifier ses tarifs à tout moment.Toutefois, elle s’engage à facturer les marchandises commandées aux prix indiqués lors de l’enregistrement de la commande.
            </p>
          </div>
          <div className={"clause-wrapper"}>
            <h3>Clause n° 3 : Rabais et ristournes</h3>
            <p>
              Les tarifs proposés comprennent les rabais et ristournes que la société <strong>Domicilier.tn</strong> serait amenée à octroyer compte tenu de ses résultats ou de la prise en charge par l’acheteur de certaines prestations.
            </p>
          </div>
          <div className={"clause-wrapper"}>
            <h3>Clause n° 4 : Escompte</h3>
            <p>
              Aucun escompte ne sera consenti en cas de paiement anticipé.
            </p>
          </div>
          <div className={"clause-wrapper"}>
            <h3>Clause n° 5 : Modalités de paiement</h3>
            <p style={{marginBottom: 0}}>Le règlement des commandes s’effectue :</p>
            <ul>
              <li>soit par chèque;</li>
              <li>soit par carte bancaire;</li>
            </ul>
            <p>
              le cas échéant, indiquer les autres moyens de paiement acceptés.
              Lors de l’enregistrement de la commande, l’acheteur devra verser un acompte de 10 % du montant global de la facture, le solde devant être payé à réception des marchandises.
            </p>
          </div>
          <div className={"clause-wrapper"}>
            <h3>Clause n° 6 : Retard de paiement</h3>
            <p>
              En cas de défaut de paiement total ou partiel des marchandises livrées au jour de la réception, l’acheteur doit verser à la société <strong>Domicilier.tn</strong> une pénalité de retard égale à trois fois le taux de l’intérêt légal.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsConditionsBlock;
