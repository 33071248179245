import React, { useEffect } from "react";
import "./about-page.scss";
import LandingFooter from "../../components/landing-footer/LandingFooter";
import AboutFirstBlock from "../../components/about-page-components/AboutFirstBlock";
import AboutSecondBlock from "../../components/about-page-components/AboutSecondBlock";
import AboutThirdBlock from "../../components/about-page-components/AboutThirdBlock";
import Team from "../../components/about-page-components/Team";
import BriefBlock from "../../components/about-page-components/BriefBlock";
const AboutPage = () => {
  useEffect(() => {
    document.title = "A propos | Domiciliation et création d'entreprise en Tunisie";
    const description = document.querySelector('meta[name="description"]');
    if(description){
      description.setAttribute("content", "Domiciliation et création d'entreprise en Tunisie 100% en ligne, contrat de domiciliation, transfert de siège social au meilleur prix en Tunisie.");
    }
  })
  return (
    <div className={"about-page"}>
      <AboutFirstBlock/>
      <AboutSecondBlock/>
      <AboutThirdBlock/>
      <Team/>
      <BriefBlock/>
      <LandingFooter />
    </div>
  );
};

export default AboutPage;
