import React from 'react';

const ScannerIconInCard = () => {
  return (
    <svg width="76" height="68" viewBox="0 0 76 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M71.2523 0H54.8288C51.9762 0 50.0338 1.06302 48.716 3.34575L43.96 11.5834L6.18846 1.46245C5.39951 1.25091 4.58912 1.71907 4.37788 2.50773C4.16649 3.29623 4.63451 4.10677 5.42316 4.31816L33.4444 11.8264H4.43479C1.98945 11.8264 0 13.8159 0 16.2612V63.5654C0 66.0106 1.98945 68 4.43479 68H10.3481C11.1646 68 11.8264 67.338 11.8264 66.5217C11.8264 65.7066 12.4896 65.0435 13.3047 65.0435H62.3825C63.1976 65.0435 63.8607 65.7066 63.8607 66.5217C63.8607 67.338 64.5227 68 65.339 68H71.2523C73.6977 68 75.6871 66.0106 75.6871 63.5654V4.43464C75.6871 1.9893 73.6977 0 71.2523 0ZM72.7306 38.4338H2.95653V20.6961H72.7306V38.4338ZM2.95653 41.3904H72.7306V50.2604H2.95653V41.3904ZM4.43479 14.7829H44.6733C45.2013 14.7829 45.6894 14.5012 45.9535 14.0438L51.2766 4.82402C52.0624 3.46269 53.0252 2.95653 54.8288 2.95653H71.2523C72.0675 2.95653 72.7306 3.61953 72.7306 4.43464V17.7396H2.95653V16.2612C2.95653 15.4462 3.61968 14.7829 4.43479 14.7829ZM72.7306 63.5654C72.7306 64.3805 72.0675 65.0435 71.2523 65.0435H66.5639C65.9538 63.3226 64.3097 62.0869 62.3825 62.0869H13.3047C11.3773 62.0869 9.73333 63.3226 9.12311 65.0435H4.43479C3.61968 65.0435 2.95653 64.3805 2.95653 63.5654V53.2169H72.7306V63.5654Z"
        fill="#3240FF"/>
      <path
        d="M28.974 59.1301H46.7132C47.5295 59.1301 48.1914 58.4681 48.1914 57.6518C48.1914 56.8356 47.5295 56.1736 46.7132 56.1736H28.974C28.1575 56.1736 27.4957 56.8356 27.4957 57.6518C27.4957 58.4681 28.1577 59.1301 28.974 59.1301Z"
        fill="#3240FF"/>
      <path
        d="M28.974 47.3039H46.7132C47.5295 47.3039 48.1914 46.642 48.1914 45.8257C48.1914 45.0094 47.5295 44.3474 46.7132 44.3474H28.974C28.1575 44.3474 27.4957 45.0094 27.4957 45.8257C27.4957 46.642 28.1577 47.3039 28.974 47.3039Z"
        fill="#3240FF"/>
      <path
        d="M43.7565 25.8694H31.9305C30.2976 25.8694 28.974 27.193 28.974 28.8259V30.3042C28.974 31.9371 30.2976 33.2607 31.9305 33.2607H43.7565C45.3894 33.2607 46.713 31.9371 46.713 30.3042V28.8259C46.713 27.1932 45.3894 25.8694 43.7565 25.8694Z"
        fill="#3240FF"/>
      <path
        d="M61.4955 25.8694H55.5829C53.95 25.8694 52.6263 27.193 52.6263 28.8259V30.3042C52.6263 31.9371 53.95 33.2607 55.5829 33.2607H61.4955C63.1284 33.2607 64.452 31.9371 64.452 30.3042V28.8259C64.452 27.1932 63.1282 25.8694 61.4955 25.8694Z"
        fill="#3240FF"/>
      <path
        d="M67.7493 9.46874C68.5656 9.46874 69.2276 8.80677 69.2276 7.99047C69.2276 7.17417 68.5656 6.51221 67.7493 6.51221H67.7483C66.932 6.51221 66.2704 7.17417 66.2704 7.99047C66.2704 8.80677 66.933 9.46874 67.7493 9.46874Z"
        fill="#3240FF"/>
      <path
        d="M61.8361 9.46874C62.6524 9.46874 63.3144 8.80677 63.3144 7.99047C63.3144 7.17417 62.6524 6.51221 61.8361 6.51221H61.8349C61.0186 6.51221 60.3572 7.17417 60.3572 7.99047C60.3572 8.80677 61.0198 9.46874 61.8361 9.46874Z"
        fill="#3240FF"/>
      <path
        d="M55.9228 9.46874C56.7391 9.46874 57.401 8.80677 57.401 7.99047C57.401 7.17417 56.7391 6.51221 55.9228 6.51221H55.9217C55.1054 6.51221 54.4439 7.17417 54.4439 7.99047C54.4439 8.80677 55.1065 9.46874 55.9228 9.46874Z"
        fill="#3240FF"/>
    </svg>

  );
};

export default ScannerIconInCard;
