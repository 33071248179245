import React, {useEffect } from "react";
import { Modal, Button } from 'antd';
import $ from "jquery";
import Saly from "../../assets/png/Saly-26.png";


interface ContactMeModalProps {
  visible: boolean,
  abort: any
}

const ContactMeModal : React.FC<ContactMeModalProps> = ({visible, abort}:any) => {
  useEffect(() => {
    if (visible) {
      $(".app-container").css({ 'filter': 'brightness(50%) blur(2px)' });
    } else {
      $(".app-container").css({ 'filter': 'brightness(100%) blur(0)' });
    }
  }, [visible])
  const closeModal = () => {
    abort();
  }
  return (
    <Modal
      centered
      visible={visible}
      onCancel={closeModal}
      style={{ top: 20 }}
      footer={<div></div>}
      wrapClassName={"contact-me-modal"}
      width={"45%"}
    >
      <div className={"contact-me-modal-content"}>
        <h2>Un conseiller client</h2>
        <p>vous contactera dans les plus brefs délais, au numéro de téléphone indiqué dans le formulaire, ou par email.</p>
        <img src={Saly} alt="img" />
        <div className={"buttons-wrapper"}>
          <Button onClick={closeModal} className={"back-btn"}>Retour</Button>
        </div>
      </div>
    </Modal>
  );
}
export default ContactMeModal;
