import React from "react";
import { Layout } from "antd";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Cookies from "../components/cookies-component/Cookies";
import LandingNavbar from "../components/landing-navbar/LandingNavbar";
import AddressesSidebar from "../components/addresses-page-components/AddressesSidebar";
import ServicesSidebar from "../pages/services-pages/ServicesSidebar";
import Hubspot from "../tools/HubSpot/Hubspot";

const { Content } = Layout;

const MainLayout = ({ location, children, ...rest }: any) => {
    
    return (
        <Layout style={{ minHeight: "100vh" }} className={"app-container"}>
            <Layout className="app-layout">
                <Hubspot />
                <Cookies />
                <LandingNavbar />
                <AddressesSidebar />
                <ServicesSidebar />
                <Content>
                    <TransitionGroup component={"section"} >
                        <CSSTransition
                            key={location.key}
                            timeout={450}
                            classNames="fade"
                            unmountOnExit
                        >
                            {children}
                        </CSSTransition>

                    </TransitionGroup>
                </Content>
            </Layout>
        </Layout>
    );
};

export default MainLayout;