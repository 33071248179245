import React from "react";
import domi from "../../assets/png/Saly-10.png";
import lightEllipse from "../../assets/png/Light-Ellipse.png";


const BlogFirstBlock = () => {
  return (
    <>
    <div className={"blog-first-block"} style={{backgroundImage: `url('${domi}')`}}>
      <img src={lightEllipse} alt="Light Ellipse" className={"ellipse-right"}/>
      <div className={"max-width-container"}>
        <div className={"content"}>
            <h1>L’actualité au service de votre entreprise !</h1>
            <p>Ne ratez aucune nouvelle concernant la domiciliation d’entreprise en Tunisie ! Restez informés des dernières nouveautés de ce domaine grâce à notre blog fait 100% pour vous !</p>
        </div>
        <div className={"mobile-image-container"}>
            <img src={domi} alt="img" />
        </div>
      </div>
    </div>
    </>
  );
};

export default BlogFirstBlock;
