import React, { useEffect } from "react";
import "./landing-page.scss";
import ServicesLandingBlock from "../../components/landing-page-components/ServicesLandingBlock";
import HowItWorks from "../../components/landing-page-components/HowItWorks";
import LandingFooter from "../../components/landing-footer/LandingFooter";
import Testimonials from "../../components/landing-page-components/Testimonials";
import Newsletter from "../../components/landing-page-components/Newsletter";
import LandingFirstBlock from "../../components/landing-page-components/LandingFirstBlock";
import FAQBlock from "../../components/landing-page-components/FAQBlock";
import AddressesCarousel from "../../components/landing-page-components/AddressesCarousel";

const LandingPage = () => {
  useEffect(() => {
    document.title = `Domiciliation et Création d'entreprise en Tunisie - 100% en ligne`
    const description = document.querySelector('meta[name="description"]');
    if (description) {
      description.setAttribute("content", "Domiciliation et Création d'entreprise en Tunisie 100% en ligne, contrat de domiciliation, transfert de siège social au meilleur prix en Tunisie.");
    }
    const canonical = document.querySelector('link[rel="canonical"]');
    if (canonical) {
      canonical.setAttribute("href", `https://domicilier.tn`);
    }
  })
  return (
    <div className={"landing-page"}>
      <LandingFirstBlock />
      <AddressesCarousel />
      <ServicesLandingBlock />
      <HowItWorks />
      <Testimonials />
      <FAQBlock />
      <Newsletter />
      <LandingFooter />
    </div>
  );
};

export default LandingPage;
