import React from "react";
import { Row, Col, Avatar, Skeleton } from 'antd';
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { UserOutlined } from '@ant-design/icons';

const AddressResponsible = () => {
  const address = useSelector((state: RootState) => state.serivcesReducer.address);
  const isLoadingAddress = useSelector((state: RootState) => state.serivcesReducer.isLoadingAddress);

  return (
    <div className="address-responsible">
      <div className={"max-width-container"}>
        <Skeleton loading={isLoadingAddress} active>
          <Row align="middle" justify="space-between" gutter={[24, 24]} style={{ width: "100%" }}>
            <Col xs={24} md={14}>
              <h3>A chaque adresse <br /> de domiciliation, <strong>son responsable</strong></h3>
            </Col>
            <Col xs={24} md={10}>
              {address && address.domiciliataire ? (
                <div className="responsible-card">
                  <Avatar size={60} icon={<UserOutlined />} src={address.domiciliataire.user.avatar ? process.env.REACT_APP_STORAGE_URL + address.domiciliataire.user.avatar : null} />
                  <div className="responsible-info">
                    <p>{address.domiciliataire.user.full_name}</p>
                    <small>{address.domiciliataire.company_name}</small>
                  </div>
                </div>
              ) : null}
            </Col>
          </Row>
        </Skeleton>
      </div >
    </div>
  )
};

export default AddressResponsible;
