import React from "react";

const ArrowNext = () => {
    return (
<svg width="55" height="21" viewBox="0 0 55 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.91084 10.7707L53.6306 10.7708" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M44.5796 1.71974L53.6306 10.7707L44.5796 19.8217" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
    );
};

export default ArrowNext;
