import React from "react";
import { Row, Col } from 'antd';
import SUARL from "../../../svg-components/icons/legal-forms/SUARL";
import SARL from "../../../svg-components/icons/legal-forms/SARL";
import SA from "../../../svg-components/icons/legal-forms/SA";
import SCA from "../../../svg-components/icons/legal-forms/SCA";
import SCS from "../../../svg-components/icons/legal-forms/SCS";
import SNC from "../../../svg-components/icons/legal-forms/SNC";

const LegalForms = () => {
  return (
    <>
    <div className={"legal-forms-block"} >
      <div className={"max-width-container"}>
        <h2>Les principales formes juridiques</h2>
        <Row className={"content"} justify={"space-between"} gutter={[24, 24]}>
          <Col xs={24} md={8}>
            <div className={"form-wrapper"}>
              <div className={"svg-container"}><SUARL/></div>
              <h3>SUARL</h3>
              <p>Capital 1000 TND</p>
              <p>Nombre d’associés: 1</p>
            </div>
          </Col>
          <Col xs={24} md={8}>
            <div className={"form-wrapper"}>
              <div className={"svg-container"}><SARL/></div>
              <h3>SARL</h3>
              <p>Capital 1000 TND</p>
              <p>Nombre d’associés: 2 à 50</p>
            </div>
          </Col>
          <Col xs={24} md={8}>
            <div className={"form-wrapper"}>
              <div className={"svg-container"}><SA/></div>
              <h3>SA</h3>
              <p>Capital 5000 TND</p>
              <p>Nombre d’associés: Au moins 7 personnes</p>
            </div>
          </Col>
          <Col xs={24} md={8}>
            <div className={"form-wrapper"}>
              <div className={"svg-container"}><SCA/></div>
              <h3>SCA</h3>
              <p>Capital 5000 TND</p>
              <p>Nombre d’associés: 1 ou plusieurs commanditaires et commandités</p>
            </div>
          </Col>
          <Col xs={24} md={8}>
            <div className={"form-wrapper"}>
              <div className={"svg-container"}><SCS/></div>
              <h3>SCS</h3>
              <p>Capital minimum : Pas de capital minimum</p>
            </div>
          </Col>
          <Col xs={24} md={8}>
            <div className={"form-wrapper"}>
              <div className={"svg-container"}><SNC/></div>
              <h3>SNC</h3>
              <p>Capital minimum : Pas de capital minimum</p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
    </>
  );
};

export default LegalForms;
