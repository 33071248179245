import React from "react";

const SARL = () => {
return (
<svg width="125" height="64" viewBox="0 0 125 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<path d="M59.3996 65.023C58.7218 61.6852 56.5675 58.939 53.7061 57.4068C55.0351 56.0673 55.8576 54.2248 55.8576 52.1932C55.8576 48.1095 52.5351 44.7871 48.4514 44.7871C44.3676 44.7871 41.0453 48.1095 41.0453 52.1932C41.0453 54.2248 41.8679 56.0674 43.1969 57.4067C41.6126 58.2551 40.2457 59.4749 39.226 60.9622C38.2062 59.475 36.8393 58.2551 35.2552 57.4068C36.5843 56.0673 37.4068 54.2248 37.4068 52.1932C37.4068 48.1095 34.0843 44.7871 30.0006 44.7871C25.9169 44.7871 22.5944 48.1095 22.5944 52.1932C22.5944 54.2248 23.417 56.0674 24.746 57.4068C23.1618 58.2551 21.7949 59.4749 20.7751 60.9624C19.7554 59.475 18.3885 58.2551 16.8043 57.4068C18.1334 56.0673 18.9559 54.2248 18.9559 52.1932C18.9559 48.1095 15.6334 44.7871 11.5497 44.7871C7.46583 44.7871 4.14346 48.1095 4.14346 52.1932C4.14346 54.2248 4.96611 56.0674 6.29513 57.4068C3.43353 58.9391 1.2794 61.6852 0.601587 65.023C0.496587 65.5399 0.629361 66.0768 0.963227 66.4853C1.29709 66.8937 1.79666 67.1308 2.32424 67.1308H20.775H39.2258H57.6766C58.2042 67.1308 58.7039 66.8938 59.0376 66.4853C59.3718 66.0768 59.5046 65.5399 59.3996 65.023Z" fill="#3240FF"/>
<path d="M35.2546 16.6199C36.5837 15.2804 37.4063 13.4377 37.4063 11.4062C37.4063 7.32249 34.0839 4 30 4C25.9162 4 22.5938 7.32249 22.5938 11.4062C22.5938 13.4377 23.4164 15.2804 24.7455 16.6198C23.4722 17.3016 22.3395 18.2242 21.4141 19.3318C25.0234 19.5415 28.1685 21.5108 30 24.3934C31.8315 21.5107 34.9766 19.5415 38.586 19.332C37.6606 18.2243 36.5278 17.3017 35.2546 16.6199Z" fill="#3240FF"/>
<path d="M56.8405 16.6199C58.1697 15.2804 58.9922 13.4377 58.9922 11.4062C58.9922 7.32249 55.6698 4 51.586 4C47.5021 4 44.1797 7.32249 44.1797 11.4062C44.1797 13.4377 45.0024 15.2804 46.3314 16.6198C45.0582 17.3016 43.9254 18.2242 43 19.3318C46.6094 19.5415 49.7544 21.5108 51.586 24.3934C53.4175 21.5107 56.5626 19.5415 60.1719 19.332C59.2465 18.2243 58.1138 17.3017 56.8405 16.6199Z" fill="#3240FF"/>
<path d="M46.2006 37.0104C47.5298 35.6709 48.3523 33.8283 48.3523 31.7969C48.3523 27.713 45.0298 24.3906 40.9462 24.3906C36.8625 24.3906 33.54 27.7131 33.54 31.7969C33.54 33.8283 34.3627 35.6711 35.6915 37.0104C34.4183 37.6922 33.2857 38.6146 32.3604 39.7226C35.9698 39.9321 39.1146 41.9014 40.9462 44.784C42.7777 41.9012 45.9228 39.932 49.5323 39.7226C48.6068 38.6149 47.474 37.6923 46.2006 37.0104Z" fill="#3240FF"/>
<path d="M66.3725 37.0104C67.7016 35.6709 68.5242 33.8283 68.5242 31.7969C68.5242 27.713 65.2017 24.3906 61.1181 24.3906C57.0343 24.3906 53.7118 27.7131 53.7118 31.7969C53.7118 33.8283 54.5346 35.6711 55.8634 37.0104C54.5902 37.6922 53.4575 38.6146 52.5322 39.7226C56.1417 39.9321 59.2864 41.9014 61.1181 44.784C62.9496 41.9012 66.0947 39.932 69.7041 39.7226C68.7787 38.6149 67.6459 37.6923 66.3725 37.0104Z" fill="#3240FF"/>
<path d="M26.0291 37.0143C27.3581 35.6749 28.1808 33.8322 28.1808 31.8008C28.1808 27.7169 24.8583 24.3945 20.7746 24.3945C16.6907 24.3945 13.3683 27.717 13.3683 31.8008C13.3683 33.8322 14.1911 35.675 15.5199 37.0143C14.2466 37.6961 13.114 38.6187 12.1885 39.7265C15.7981 39.936 18.9429 41.9052 20.7746 44.7879C22.6062 41.9052 25.7509 39.9361 29.3602 39.7266C28.4352 38.6186 27.3025 37.6962 26.0291 37.0143Z" fill="#3240FF"/>
<path d="M120.268 65.025C119.59 61.6871 117.436 58.941 114.574 57.4087C115.903 56.0693 116.726 54.2267 116.726 52.1952C116.726 48.1114 113.403 44.7891 109.32 44.7891C105.236 44.7891 101.913 48.1114 101.913 52.1952C101.913 54.2267 102.736 56.0694 104.065 57.4086C102.481 58.2571 101.114 59.4769 100.094 60.9642C99.0743 59.477 97.7075 58.2571 96.1233 57.4087C97.4525 56.0693 98.275 54.2267 98.275 52.1952C98.275 48.1114 94.9525 44.7891 90.8688 44.7891C86.785 44.7891 83.4625 48.1114 83.4625 52.1952C83.4625 54.2267 84.2852 56.0694 85.6142 57.4087C84.0299 58.2571 82.6631 59.4769 81.6433 60.9643C80.6235 59.477 79.2567 58.2571 77.6724 57.4087C79.0016 56.0693 79.8241 54.2267 79.8241 52.1952C79.8241 48.1114 76.5016 44.7891 72.4179 44.7891C68.334 44.7891 65.0116 48.1114 65.0116 52.1952C65.0116 54.2267 65.8343 56.0694 67.1633 57.4087C64.3017 58.9411 62.1476 61.6871 61.4698 65.025C61.3648 65.5419 61.4975 66.0787 61.8314 66.4872C62.1653 66.8956 62.6648 67.1327 63.1924 67.1327H81.6432H100.094H118.545C119.072 67.1327 119.572 66.8958 119.906 66.4872C120.24 66.0787 120.373 65.5419 120.268 65.025Z" fill="#3240FF"/>
<path d="M98.8405 16.6199C100.17 15.2804 100.992 13.4377 100.992 11.4062C100.992 7.32249 97.6698 4 93.586 4C89.5021 4 86.1797 7.32249 86.1797 11.4062C86.1797 13.4377 87.0024 15.2804 88.3314 16.6198C87.0582 17.3016 85.9254 18.2242 85 19.3318C88.6094 19.5415 91.7544 21.5108 93.586 24.3934C95.4175 21.5107 98.5626 19.5415 102.172 19.332C101.246 18.2243 100.114 17.3017 98.8405 16.6199Z" fill="#3240FF"/>
<path d="M77.8405 16.6199C79.1697 15.2804 79.9922 13.4377 79.9922 11.4062C79.9922 7.32249 76.6698 4 72.586 4C68.5021 4 65.1797 7.32249 65.1797 11.4062C65.1797 13.4377 66.0024 15.2804 67.3314 16.6198C66.0582 17.3016 64.9254 18.2242 64 19.3318C67.6094 19.5415 70.7544 21.5108 72.586 24.3934C74.4175 21.5107 77.5626 19.5415 81.1719 19.332C80.2465 18.2243 79.1138 17.3017 77.8405 16.6199Z" fill="#3240FF"/>
<path d="M106.716 37.0104C108.045 35.6709 108.868 33.8283 108.868 31.7969C108.868 27.713 105.545 24.3906 101.462 24.3906C97.3781 24.3906 94.0556 27.7131 94.0556 31.7969C94.0556 33.8283 94.8784 35.6711 96.2071 37.0104C94.9339 37.6922 93.8013 38.6146 92.876 39.7226C96.4855 39.9321 99.6302 41.9014 101.462 44.784C103.293 41.9012 106.438 39.932 110.048 39.7226C109.122 38.6149 107.99 37.6923 106.716 37.0104Z" fill="#3240FF"/>
<path d="M86.5447 37.0104C87.8738 35.6709 88.6964 33.8283 88.6964 31.7969C88.6964 27.713 85.3739 24.3906 81.2902 24.3906C77.2063 24.3906 73.8839 27.7131 73.8839 31.7969C73.8839 33.8283 74.7067 35.6711 76.0355 37.0104C74.7623 37.6922 73.6296 38.6148 72.7041 39.7226C76.3137 39.9321 79.4585 41.9013 81.2902 44.784C83.1218 41.9013 86.2665 39.9322 89.8758 39.7227C88.9508 38.6147 87.8181 37.6923 86.5447 37.0104Z" fill="#3240FF"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="125" height="64" fill="white"/>
</clipPath>
</defs>
</svg>
);
};

export default SARL;
