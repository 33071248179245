import React from "react";

const SA = () => {
  return (
    <svg width="197" height="64" viewBox="0 0 197 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_0:4)">
        <path d="M69.8332 63.026C69.1554 59.6881 67.0011 56.9419 64.1396 55.4097C65.4687 54.0703 66.2912 52.2277 66.2912 50.1962C66.2912 46.1124 62.9687 42.79 58.885 42.79C54.8012 42.79 51.4789 46.1124 51.4789 50.1962C51.4789 52.2277 52.3015 54.0704 53.6305 55.4096C52.0462 56.258 50.6793 57.4778 49.6596 58.9652C48.6398 57.478 47.2729 56.258 45.6887 55.4097C47.0179 54.0703 47.8404 52.2277 47.8404 50.1962C47.8404 46.1124 44.5179 42.79 40.4342 42.79C36.3504 42.79 33.028 46.1124 33.028 50.1962C33.028 52.2277 33.8506 54.0704 35.1796 55.4097C33.5954 56.258 32.2285 57.4778 31.2087 58.9653C30.189 57.478 28.8221 56.258 27.2378 55.4097C28.567 54.0703 29.3895 52.2277 29.3895 50.1962C29.3895 46.1124 26.067 42.79 21.9833 42.79C17.8994 42.79 14.577 46.1124 14.577 50.1962C14.577 52.2277 15.3997 54.0704 16.7287 55.4097C13.8671 56.9421 11.713 59.6881 11.0352 63.026C10.9302 63.5429 11.063 64.0797 11.3968 64.4882C11.7307 64.8966 12.2303 65.1337 12.7578 65.1337H31.2086H49.6594H68.1102C68.6378 65.1337 69.1375 64.8967 69.4712 64.4882C69.8054 64.0797 69.9382 63.5429 69.8332 63.026Z" fill="#3240FF" />
        <path d="M127.701 63.026C127.024 59.6881 124.869 56.9419 122.008 55.4097C123.337 54.0703 124.159 52.2277 124.159 50.1962C124.159 46.1124 120.837 42.79 116.753 42.79C112.669 42.79 109.347 46.1124 109.347 50.1962C109.347 52.2277 110.17 54.0704 111.499 55.4096C109.914 56.258 108.547 57.4778 107.528 58.9652C106.508 57.478 105.141 56.258 103.557 55.4097C104.886 54.0703 105.709 52.2277 105.709 50.1962C105.709 46.1124 102.386 42.79 98.3024 42.79C94.2186 42.79 90.8961 46.1124 90.8961 50.1962C90.8961 52.2277 91.7188 54.0704 93.0478 55.4097C91.4635 56.258 90.0967 57.4778 89.0769 58.9653C88.0571 57.478 86.6903 56.258 85.106 55.4097C86.4351 54.0703 87.2577 52.2277 87.2577 50.1962C87.2577 46.1124 83.9352 42.79 79.8514 42.79C75.7676 42.79 72.4452 46.1124 72.4452 50.1962C72.4452 52.2277 73.2679 54.0704 74.5969 55.4097C71.7353 56.9421 69.5812 59.6881 68.9033 63.026C68.7983 63.5429 68.9311 64.0797 69.265 64.4882C69.5989 64.8966 70.0984 65.1337 70.626 65.1337H89.0768H107.528H125.978C126.506 65.1337 127.006 64.8967 127.339 64.4882C127.674 64.0797 127.806 63.5429 127.701 63.026Z" fill="#3240FF" />
        <path d="M185.57 63.023C184.892 59.6852 182.737 56.939 179.876 55.4068C181.205 54.0673 182.028 52.2248 182.028 50.1932C182.028 46.1095 178.705 42.7871 174.621 42.7871C170.538 42.7871 167.215 46.1095 167.215 50.1932C167.215 52.2248 168.038 54.0674 169.367 55.4067C167.782 56.2551 166.416 57.4749 165.396 58.9622C164.376 57.475 163.009 56.2551 161.425 55.4068C162.754 54.0673 163.577 52.2248 163.577 50.1932C163.577 46.1095 160.254 42.7871 156.171 42.7871C152.087 42.7871 148.764 46.1095 148.764 50.1932C148.764 52.2248 149.587 54.0674 150.916 55.4068C149.332 56.2551 147.965 57.4749 146.945 58.9624C145.925 57.475 144.558 56.2551 142.974 55.4068C144.303 54.0673 145.126 52.2248 145.126 50.1932C145.126 46.1095 141.803 42.7871 137.72 42.7871C133.636 42.7871 130.313 46.1095 130.313 50.1932C130.313 52.2248 131.136 54.0674 132.465 55.4068C129.603 56.9391 127.449 59.6852 126.772 63.023C126.667 63.5399 126.799 64.0768 127.133 64.4853C127.467 64.8937 127.967 65.1308 128.494 65.1308H146.945H165.396H183.847C184.374 65.1308 184.874 64.8938 185.208 64.4853C185.542 64.0768 185.675 63.5399 185.57 63.023Z" fill="#3240FF" />
        <path d="M73.6518 12.6198C74.9809 11.2803 75.8035 9.43767 75.8035 7.40623C75.8035 3.32238 72.481 0 68.3974 0C64.3136 0 60.9911 3.32249 60.9911 7.40623C60.9911 9.43767 61.8139 11.2804 63.1427 12.6198C61.8694 13.3016 60.7368 14.224 59.8115 15.332C63.421 15.5415 66.5657 17.5108 68.3974 20.3934C70.2289 17.5106 73.374 15.5414 76.9834 15.332C76.058 14.2243 74.9252 13.3017 73.6518 12.6198Z" fill="#3240FF" />
        <path d="M93.6518 12.6198C94.9809 11.2803 95.8035 9.43767 95.8035 7.40623C95.8035 3.32237 92.481 0 88.3974 0C84.3136 0 80.9911 3.32249 80.9911 7.40623C80.9911 9.43767 81.8139 11.2804 83.1427 12.6198C81.8694 13.3016 80.7368 14.224 79.8115 15.332C83.421 15.5415 86.5657 17.5108 88.3974 20.3934C90.2289 17.5106 93.374 15.5414 96.9834 15.332C96.058 14.2243 94.9252 13.3017 93.6518 12.6198Z" fill="#3240FF" />
        <path d="M53.8406 12.6198C55.1697 11.2803 55.9923 9.43767 55.9923 7.40623C55.9923 3.32237 52.6698 0 48.5861 0C44.5022 0 41.1798 3.32249 41.1798 7.40623C41.1798 9.43767 42.0026 11.2804 43.3314 12.6198C42.0582 13.3016 40.9255 14.2242 40 15.332C43.6096 15.5415 46.7544 17.5107 48.5861 20.3934C50.4177 17.5107 53.5624 15.5416 57.1717 15.3321C56.2467 14.2241 55.114 13.3017 53.8406 12.6198Z" fill="#3240FF" />
        <path d="M153.652 12.6198C154.981 11.2803 155.804 9.43767 155.804 7.40623C155.804 3.32238 152.481 0 148.398 0C144.314 0 140.991 3.32249 140.991 7.40623C140.991 9.43767 141.814 11.2804 143.143 12.6198C141.87 13.3016 140.737 14.2242 139.812 15.332C143.421 15.5415 146.566 17.5107 148.398 20.3934C150.229 17.5107 153.374 15.5416 156.983 15.3321C156.058 14.2241 154.926 13.3017 153.652 12.6198Z" fill="#3240FF" />
        <path d="M133.652 12.6198C134.981 11.2803 135.803 9.43767 135.803 7.40623C135.803 3.32238 132.481 0 128.397 0C124.314 0 120.991 3.32249 120.991 7.40623C120.991 9.43767 121.814 11.2804 123.143 12.6198C121.869 13.3016 120.737 14.224 119.812 15.332C123.421 15.5415 126.566 17.5108 128.397 20.3934C130.229 17.5106 133.374 15.5414 136.983 15.332C136.058 14.2243 134.925 13.3017 133.652 12.6198Z" fill="#3240FF" />
        <path d="M113.652 12.6198C114.981 11.2803 115.804 9.43767 115.804 7.40623C115.804 3.32238 112.481 0 108.398 0C104.314 0 100.991 3.32249 100.991 7.40623C100.991 9.43767 101.814 11.2804 103.143 12.6198C101.87 13.3016 100.737 14.2242 99.8115 15.332C103.421 15.5415 106.566 17.5107 108.398 20.3934C110.229 17.5107 113.374 15.5416 116.983 15.3321C116.058 14.2241 114.926 13.3017 113.652 12.6198Z" fill="#3240FF" />
        <path d="M32.8405 34.0135C34.1697 32.674 34.9922 30.8312 34.9922 28.7998C34.9922 24.716 31.6698 21.3936 27.586 21.3936C23.5021 21.3936 20.1797 24.716 20.1797 28.7998C20.1797 30.8312 21.0024 32.674 22.3314 34.0133C21.0582 34.6951 19.9254 35.6177 19 36.7254C22.6094 36.935 25.7544 38.9044 27.586 41.787C29.4175 38.9043 32.5626 36.935 36.1719 36.7255C35.2465 35.6179 34.1138 34.6952 32.8405 34.0135Z" fill="#3240FF" />
        <path d="M53.0124 34.0135C54.3415 32.674 55.1641 30.8312 55.1641 28.7998C55.1641 24.716 51.8417 21.3936 47.7578 21.3936C43.674 21.3936 40.3516 24.716 40.3516 28.7998C40.3516 30.8312 41.1743 32.674 42.5033 34.0133C41.23 34.6951 40.0973 35.6177 39.1719 36.7254C42.7812 36.935 45.9263 38.9044 47.7578 41.787C49.5894 38.9043 52.7344 36.935 56.3438 36.7255C55.4184 35.6179 54.2856 34.6952 53.0124 34.0135Z" fill="#3240FF" />
        <path d="M113.528 34.0133C114.857 32.6739 115.679 30.8312 115.679 28.7998C115.679 24.7159 112.357 21.3936 108.273 21.3936C104.19 21.3936 100.867 24.716 100.867 28.7998C100.867 30.8312 101.69 32.674 103.019 34.0133C101.745 34.6951 100.613 35.6175 99.6875 36.7255C103.297 36.935 106.442 38.9044 108.273 41.7869C110.105 38.9041 113.25 36.9349 116.859 36.7255C115.934 35.6179 114.801 34.6952 113.528 34.0133Z" fill="#3240FF" />
        <path d="M133.7 34.0133C135.029 32.6739 135.851 30.8312 135.851 28.7998C135.851 24.7159 132.529 21.3936 128.445 21.3936C124.361 21.3936 121.039 24.716 121.039 28.7998C121.039 30.8312 121.862 32.674 123.191 34.0133C121.917 34.6951 120.785 35.6175 119.859 36.7255C123.469 36.935 126.614 38.9044 128.445 41.7869C130.277 38.9041 133.422 36.9349 137.031 36.7255C136.106 35.6179 134.973 34.6952 133.7 34.0133Z" fill="#3240FF" />
        <path d="M93.3561 34.0135C94.6853 32.674 95.5078 30.8312 95.5078 28.7998C95.5078 24.716 92.1854 21.3936 88.1016 21.3936C84.0177 21.3936 80.6953 24.716 80.6953 28.7998C80.6953 30.8312 81.518 32.674 82.847 34.0133C81.5738 34.6951 80.4411 35.6177 79.5156 36.7254C83.125 36.935 86.2701 38.9044 88.1016 41.787C89.9331 38.9043 93.0782 36.935 96.6875 36.7255C95.7621 35.6179 94.6294 34.6952 93.3561 34.0135Z" fill="#3240FF" />
        <path d="M73.1843 34.0135C74.5134 32.674 75.3359 30.8312 75.3359 28.7998C75.3359 24.716 72.0136 21.3936 67.9297 21.3936C63.8459 21.3936 60.5235 24.716 60.5235 28.7998C60.5235 30.8312 61.3461 32.674 62.6751 34.0133C61.4019 34.6951 60.2692 35.6177 59.3438 36.7254C62.9531 36.935 66.0982 38.9044 67.9297 41.787C69.7612 38.9043 72.9063 36.935 76.5157 36.7255C75.5902 35.6179 74.4575 34.6952 73.1843 34.0135Z" fill="#3240FF" />
        <path d="M174.043 34.0133C175.373 32.6739 176.195 30.8312 176.195 28.7998C176.195 24.7159 172.873 21.3936 168.789 21.3936C164.705 21.3936 161.383 24.716 161.383 28.7998C161.383 30.8312 162.206 32.674 163.534 34.0133C162.261 34.6951 161.128 35.6175 160.203 36.7255C163.813 36.935 166.957 38.9044 168.789 41.7869C170.62 38.9041 173.766 36.9349 177.375 36.7255C176.45 35.6179 175.317 34.6952 174.043 34.0133Z" fill="#3240FF" />
        <path d="M153.872 34.0133C155.201 32.6739 156.024 30.8312 156.024 28.7998C156.024 24.7159 152.701 21.3936 148.617 21.3936C144.533 21.3936 141.211 24.716 141.211 28.7998C141.211 30.8312 142.034 32.674 143.363 34.0133C142.089 34.6951 140.957 35.6177 140.031 36.7255C143.641 36.935 146.786 38.9043 148.617 41.7869C150.449 38.9043 153.594 36.9352 157.203 36.7256C156.278 35.6176 155.145 34.6952 153.872 34.0133Z" fill="#3240FF" />
      </g>
      <defs>
        <clipPath id="clip0_0:4">
          <rect width="197" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
};

export default SA;
