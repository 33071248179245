import React from "react";

const SCA = () => {
return (
<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.5873 24.6343L12.138 24.9528V28.4474L9.62882 29.0745L15.4483 32.4695L15.4063 34.5099L15.3169 34.8224L18.7587 34.0562V33.2389C18.7587 31.7556 19.3578 30.3973 20.339 29.3935L16.5529 28.4474L16.5485 24.9555L17.1003 24.6365C18.8039 23.65 19.8621 21.8202 19.8621 19.8617V17.9328C19.8621 14.8278 17.4634 12.2292 14.5146 12.1408C13.0205 12.0826 11.583 12.6473 10.5027 13.6947C9.42246 14.7426 8.82764 16.15 8.82764 17.6548V19.8617C8.82764 21.8202 9.88475 23.65 11.5873 24.6343Z" fill="#3240FF"/>
<path d="M19.8105 12.3125C22.4037 8.13793 27.0125 5.51724 32.0002 5.51724C36.9522 5.51724 41.5481 8.11369 44.1489 12.2473C44.193 12.2031 44.2318 12.1546 44.2771 12.1105C44.9646 11.4429 45.7545 10.9283 46.6063 10.562L47.8579 7.63147L44.3369 4.10938L41.234 6.12877L40.6402 5.76455C39.1526 4.85291 37.5449 4.18534 35.8622 3.78287L35.1823 3.61907L34.4156 0H29.5837L28.818 3.61907L28.1392 3.78125C26.4565 4.18427 24.8488 4.85183 23.3611 5.76347L22.7674 6.12769L19.6645 4.1083L16.1392 7.63362L17.3773 10.5851C18.2911 10.9968 19.1165 11.5862 19.8105 12.3125Z" fill="#3240FF"/>
<path d="M0 25.3789V45.5486L4.73599 47.1278L8.7403 31.1111L0 25.3789Z" fill="#3240FF"/>
<path d="M45.2412 26.1939C43.1846 24.7575 41.9308 22.3884 41.9308 19.8625V17.6556C41.9308 16.4396 42.2164 15.2731 42.7417 14.2198C40.6539 10.2591 36.5009 7.72461 31.9998 7.72461C27.4556 7.72461 23.2848 10.2936 21.2126 14.3087C21.7562 15.4024 22.0687 16.6352 22.0687 17.9337V19.8625C22.0687 22.3884 20.8139 24.7596 18.7573 26.195V26.7235L23.1722 27.8286L27.586 26.7235V26.1939C25.5294 24.7575 24.2756 22.3884 24.2756 19.8625V17.6556C24.2756 15.5479 25.1086 13.5797 26.6215 12.112C28.1334 10.6443 30.1248 9.87439 32.2368 9.93474C36.3651 10.0597 39.7239 13.647 39.7239 17.9337V19.8625C39.7239 22.3884 38.4691 24.7596 36.4125 26.195V26.7235L40.8274 27.8286L45.2412 26.7235V26.1939Z" fill="#3240FF"/>
<path d="M13.2413 33.7372L10.7246 32.2695L6.83447 47.8283L9.00635 48.5519H9.09902L13.2413 34.0524V33.7372Z" fill="#3240FF"/>
<path d="M23.4723 30.0266C21.9971 30.3962 20.9653 31.7173 20.9653 33.2389V33.5654L21.5979 33.4253C23.9282 32.9091 26.3921 33.018 28.6685 33.7475L33.6636 35.3439C34.2164 35.3649 34.7671 35.4258 35.3005 35.5783L42.0855 37.5169H42.7617L43.0343 37.3736V33.2389C43.0343 31.7173 42.0025 30.3962 40.5273 30.0266L34.2078 28.4474L34.2035 24.9555L34.7552 24.6365C36.4589 23.65 37.5171 21.8202 37.5171 19.8617V17.9328C37.5171 14.8278 35.1183 12.2292 32.1695 12.1408C30.6803 12.0826 29.238 12.6473 28.1577 13.6947C27.0774 14.7426 26.4826 16.15 26.4826 17.6548V19.8617C26.4826 21.8202 27.5397 23.65 29.2423 24.6343L29.7929 24.9528V28.4474L23.4723 30.0266Z" fill="#3240FF"/>
<path d="M44.138 17.6548V19.8617C44.138 21.8202 45.1951 23.65 46.8977 24.6343L47.4483 24.9528V28.4474L43.6611 29.3935C44.6412 30.3973 45.2414 31.7545 45.2414 33.2389V36.2114L57.3761 29.8245L51.8632 28.4474L51.8589 24.9555L52.4106 24.6365C54.1143 23.65 55.1724 21.8202 55.1724 19.8617V17.9328C55.1724 14.8278 52.7737 12.2292 49.8249 12.1408C48.3276 12.0826 46.8944 12.6473 45.8131 13.6947C44.7328 14.7426 44.138 16.15 44.138 17.6548Z" fill="#3240FF"/>
<path d="M34.4207 43.6096C33.7079 42.9684 32.6002 42.859 31.7747 43.3547L27.6654 45.8186C27.0942 46.1629 26.4353 46.345 25.7656 46.345C23.7273 46.345 22.0689 44.6866 22.0689 42.6483V42.4301C22.0689 41.0217 22.8512 39.755 24.1126 39.1241L29.6233 36.3687L27.9978 35.8488C26.091 35.2389 24.0307 35.144 22.0775 35.581L14.6282 37.2372L11.6567 47.637L15.0468 49.671C15.1438 49.5406 15.2397 49.4091 15.3556 49.2933L17.2952 47.3536C17.9342 46.7125 18.8216 46.345 19.7284 46.345C21.5856 46.345 23.0953 47.8256 23.16 49.6677C24.9601 49.7313 26.4067 51.178 26.4703 52.9781C28.2704 53.0417 29.7171 54.4883 29.7807 56.2884C31.0862 56.3348 32.2009 57.1079 32.7435 58.2168L35.2063 60.2696C35.5301 60.5395 35.9428 60.6898 36.3663 60.6898C37.001 60.6898 37.5172 60.1737 37.5172 59.539C37.5172 59.1957 37.3658 58.8736 37.1034 58.6548L31.2925 53.8132L32.7063 52.1171L38.6998 57.1112C38.9062 57.2846 39.1681 57.3795 39.4358 57.3795H39.6767C40.3114 57.3795 40.8275 56.8633 40.8275 56.2286C40.8275 55.8854 40.6761 55.5632 40.4137 55.3445L34.6029 50.5029L36.0167 48.8067L42.0005 53.7933C42.2122 53.9711 42.4838 54.0691 42.7597 54.0691H42.9504C43.6061 54.0691 44.1379 53.5373 44.1379 52.8827C44.1379 52.5465 43.9946 52.2254 43.7451 52.0002L34.4207 43.6096Z" fill="#3240FF"/>
<path d="M43.3071 39.7232L41.6288 39.6812L34.6945 37.7006C33.5593 37.3741 32.3087 37.4931 31.2554 38.0195L25.1002 41.0971C24.5916 41.352 24.2759 41.8617 24.2759 42.429V42.6472C24.2759 43.4683 24.9434 44.137 25.7656 44.137C26.035 44.137 26.2985 44.0643 26.5291 43.9263L30.6396 41.4613C31.3314 41.0454 32.1202 40.8266 32.9257 40.8266C34.0259 40.8266 35.0819 41.2318 35.8987 41.9678L45.2204 50.3584C45.2867 50.4177 45.3497 50.4786 45.41 50.5427L64 41.248V28.832L43.3071 39.7232Z" fill="#3240FF"/>
<path d="M32.7898 61.1289C32.6201 61.5077 32.3906 61.8584 32.0948 62.1542L31.2661 62.9829L32.0915 63.7108C32.3011 63.8967 32.5716 63.9991 32.8518 63.9991H33.056C33.6907 63.9991 34.2068 63.4829 34.2068 62.8482C34.2068 62.505 34.0554 62.1828 33.793 61.964L32.7898 61.1289Z" fill="#3240FF"/>
<path d="M29.6596 58.4824C29.3342 58.4824 29.0152 58.6139 28.7846 58.8445L26.845 60.7841C26.6144 61.0148 26.4829 61.3337 26.4829 61.6591C26.4829 62.3413 27.0379 62.8962 27.72 62.8962C28.0454 62.8962 28.3644 62.7648 28.595 62.5341L30.5346 60.5945C30.7652 60.3639 30.8967 60.0449 30.8967 59.7195C30.8967 59.0374 30.3417 58.4824 29.6596 58.4824Z" fill="#3240FF"/>
<path d="M24.2761 53.0984C24.2761 52.4163 23.7211 51.8613 23.039 51.8613C22.7136 51.8613 22.3946 51.9928 22.164 52.2234L20.2244 54.1631C19.9938 54.3937 19.8623 54.7126 19.8623 55.0381C19.8623 55.7202 20.4173 56.2751 21.0994 56.2751C21.4248 56.2751 21.7438 56.1437 21.9744 55.9131L23.914 53.9734C24.1446 53.7428 24.2761 53.4238 24.2761 53.0984Z" fill="#3240FF"/>
<path d="M27.5862 56.4089C27.5862 55.7268 27.0312 55.1719 26.3491 55.1719C26.0237 55.1719 25.7047 55.3033 25.4741 55.5339L23.5344 57.4736C23.3038 57.7042 23.1724 58.0232 23.1724 58.3486C23.1724 59.0307 23.7273 59.5857 24.4094 59.5857C24.7349 59.5857 25.0538 59.4542 25.2844 59.2236L27.2241 57.2839C27.4547 57.0533 27.5862 56.7344 27.5862 56.4089Z" fill="#3240FF"/>
<path d="M20.9655 49.7879C20.9655 49.1057 20.4106 48.5508 19.7285 48.5508C19.403 48.5508 19.0841 48.6822 18.8535 48.9129L16.9138 50.8525C16.6832 51.0831 16.5518 51.4021 16.5518 51.7275C16.5518 52.4096 17.1067 52.9646 17.7888 52.9646C18.1143 52.9646 18.4332 52.8331 18.6638 52.6025L20.6035 50.6629C20.8341 50.4322 20.9655 50.1133 20.9655 49.7879Z" fill="#3240FF"/>
</svg>
);
};

export default SCA;
