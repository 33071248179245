import React from 'react'
import { initGA, trackingPageGA } from "../../tools/GoogleAnalytics/UseGoogleAnalytics";
import { initiate } from "../../tools/GoogleAnalytics/UseGoogleAnalyticsV4";
import { initPIXEL, trackingPagePIXEL } from "../../tools/FacebookPixel/UseFacebookPixel";

declare const window: any;

const useCookies = () => {

    const trackData = (path: string) => {
        if(process.env.NODE_ENV === "production"){
            if (typeof window.fbq === 'undefined') {
                initiate();
                initGA(path);
                initPIXEL();
            } else {
                trackingPageGA(path);
                initiate();
                trackingPagePIXEL();
            }
        }
    }

    return {
        trackData
    }
}

export default useCookies;