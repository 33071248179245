import React from "react";
import lightEllipse from "../../assets/png/Light-Ellipse.png";
import domi from "../../assets/png/2_thumb_up_man.png";

const TermsOfUseFirstBlock = () => {

  return (
    <>
      <div className={"terms-conditions-first-block"} style={{ backgroundImage: `url('${domi}')` }}>
        <img src={lightEllipse} alt="Light Ellipse" className={"ellipse-right"} />
        <div className={"max-width-container"}>
          <div className={"content"}>
            <h1>Mentions légales et Conditions générales de vente et d’utilisation</h1>
            <p>
              Veuillez lire attentivement les mentions légales et les conditions générales de vente et d’utilisation avant d'utiliser les services proposés par la sociéte Domicilier.tn sur son site internet accessible à l’adresse <a href="https://www.domicilier.tn">www.domicilier.tn</a>.
            </p>
            <div className={"mobile-image-container"}>
              <img src={domi} alt="img" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsOfUseFirstBlock;
