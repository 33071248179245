import React, { useEffect, useState } from "react";
import { Breadcrumb, Row, Col, Space, Button } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { Link } from "react-router-dom";
import AddressGallery from "./AddressGallery";
import FlyingArrowMessageIcon from "../../svg-components/icons/FlyingArrowMessageIcon";
import ScannerIconInCard from "../../svg-components/icons/ScannerIconInCard";
import MeetingTeamIcon from "../../svg-components/MeetingTeamIcon";
import CallCenterIcon from "../../svg-components/CallCenterIcon";
import HouseSmallIcon from "../../svg-components/icons/HouseSmallIcon";

const AddressDetailsCard = () => {
  const address = useSelector((state: RootState) => state.serivcesReducer.address);
  const [iframeIsLoaded, setIsLoaded] = useState(false);
  const [services, setServices] = useState<Array<{ icon: JSX.Element, name: string }>>([]);

  const postCrossDomainMessage = (msg: any) => {
    var ifrm: any = document.getElementById('ifr');
    if (ifrm) {
      ifrm = ifrm.contentWindow || ifrm.contentDocument.document || ifrm.contentDocument;
      ifrm.postMessage(msg, "*");
    }
  }
  const onClickStart = (e: any) => {
    e.preventDefault();
    if (iframeIsLoaded && address) {
      var postMsg = {
        cart: {
          address: {
            id: address.id,
            address_line_1: address.address_line_1,
            address_line_2: address.address_line_2,
            city: address.city,
            state: address.state,
            lat: address.lat,
            long: address.long,
            zip_code: address.zip_code,
            full_location: address.full_location,
            from_price: address.from_price,
            promo: address.promo
          },
          packs: [],
          billing: {
            type: 1,
            bill_num: null,
            totalHt: 0,
          },
        }
      };
      postCrossDomainMessage(JSON.stringify(postMsg));
      setTimeout(() => {
        window.open(process.env.REACT_APP_APP_URL + "/signup", "_self");
      }, 500);
    }
  }
  const getServicesData = () => {
    let all: Array<{ icon: JSX.Element, name: string }> = [];
    if (address.domiciliation_services && address.domiciliation_services.length > 0) {
      address.domiciliation_services.map((offer: any, i: number) => {
        if (offer.service.icon) {
          all.push({
            icon: <img src={process.env.REACT_APP_STORAGE_URL + offer.service.icon} alt="icon" title={offer.service.name} />,
            name: offer.service.name
          });
        } else {
          offer.service.name.includes("Réexpédition") ?
            all.push({
              icon: <FlyingArrowMessageIcon />,
              name: offer.service.name
            })
            : offer.service.name.includes("Numérisation") ?
              all.push({
                icon: <ScannerIconInCard />,
                name: offer.service.name
              })
              :
              all.push({
                icon: <HouseSmallIcon />,
                name: offer.service.name
              })
        }

      })
    }
    if (address.meeting_services && address.meeting_services.length > 0) {
      all.push({
        icon: <MeetingTeamIcon />,
        name: 'Meeting Room'
      })
    }
    if (address.phone_services && address.phone_services.length > 0) {
      all.push({
        icon: <CallCenterIcon />,
        name: 'Permanence téléphonique'
      })
    }
    setServices(all);
  }
  useEffect(() => {
    if (address && address.id) {
      getServicesData();
    }
  }, [address])
  return (
    <div className={"max-width-container address-details-card"}>
      {address ? (
        <>
          <iframe style={{ display: "none" }} onLoad={(e) => setIsLoaded(true)} src={process.env.REACT_APP_APP_URL + "/get-post-from-site.html"} id="ifr"></iframe>
          <Breadcrumb className={"addresses-breadcrumb"} separator={<span>{">"}</span>}>
            <Breadcrumb.Item>
              <Link to="/addresses">Toutes nos adresses</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={
                {
                  pathname: "/addresses",
                  search: `?state=${address.state || null}`,
                  state: {
                    filter: address.state || null
                  }
                }
              }>{address.state || null}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span className={"active-item"}>{address.city ? window.capitalize(address.city) : null}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
          <Row className={"address-details-wrapper"} gutter={[24, 24]}>
            <Col xs={24} md={14}>
              <AddressGallery />
            </Col>
            <Col xs={24} md={8}>
              <div style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                <div className={"address-details-content"}>
                  <div className={"address-details-location"}>
                    <p className={"city"}>{address.city || null}</p>
                    <p className={"address_line"}>{address.address_line_1}, {address.zip_code}</p>
                  </div>
                  {address.from_price ? (
                    <div className={"starting-price"}>
                      <span>A partir de </span>
                      {address.promo ? (
                        address.from_price ? (
                          <Space>
                            <div>{(address.from_price - address.from_price * address.promo / 100).toLocaleString('fr-FR', { minimumFractionDigits: 3 })} Dt/mois</div>
                            <div style={{ textDecoration: "line-through" }}>{address.from_price.toLocaleString('fr-FR', { minimumFractionDigits: 3 })} Dt/mois</div>
                          </Space>
                        ) : null
                      ) : (
                        address.from_price.toLocaleString('fr-FR', { minimumFractionDigits: 3 }) + " Dt/mois"
                      )}
                    </div>
                  ) : null}
                  <div className={"address-details-description"}>
                    {address.description}
                  </div>
                  <div className={"available-services-wrapper"}>
                    <h3>Services disponibles</h3>
                    {services.length > 0 ? services.map((service, i) => (
                      <div key={i} className={"service-item"}><div className={"icon-container"}>
                        {service.icon}
                      </div>{service.name}</div>
                    )) : (
                      <em style={{ color: "#bfbfbf", textAlign: "center", display: "block" }}>Aucun service</em>
                    )}
                  </div>
                </div>
                <Button onClick={onClickStart} className={"simulator-btn"}>Estimer ma domiciliation</Button>
              </div>
            </Col>
          </Row>
        </>
      ) : null}

    </div>
  );
};

export default AddressDetailsCard;
