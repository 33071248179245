import React from "react";

const FlyingLettreSmallIcon = () => {
  return (
    <svg viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M59.1368 20.0064L40.6068 1.50212C38.6012 -0.500708 35.3377 -0.500708 33.3321 1.50212L4.41418 30.38C3.41143 31.3815 2.91 32.6969 2.91 34.0123C2.91 35.3277 3.41143 36.6431 4.41418 37.6446L9.06345 42.2874L3.9584 47.3854C3.72921 47.6143 3.61468 47.9141 3.61468 48.214C3.61468 48.5139 3.72921 48.8138 3.9584 49.0426C4.41665 49.5002 5.1597 49.5004 5.61795 49.0426L10.723 43.9447L12.8493 46.0681L0.904263 57.9966C0.67508 58.2254 0.560547 58.5254 0.560547 58.8252C0.560547 59.1251 0.67508 59.4251 0.904263 59.6539C1.36251 60.1115 2.10557 60.1115 2.56393 59.6539L14.509 47.7253L22.9441 56.1488C24.9498 58.1515 28.2132 58.1515 30.2188 56.1488L59.1368 27.2709C60.1083 26.3009 60.6434 25.0108 60.6434 23.6387C60.6434 22.2666 60.1083 20.9766 59.1368 20.0064ZM25.7288 55.1741C25.3172 55.0432 24.9295 54.8171 24.6035 54.4916L6.07374 35.9874C5.34253 35.2571 5.10408 34.2203 5.35345 33.2879H25.7288V55.1741ZM21.487 30.9441L24.9667 27.4692C25.425 27.0116 25.4251 26.2696 24.9667 25.812C24.5084 25.3544 23.7654 25.3542 23.3072 25.812L18.1678 30.9441H14.7488L25.3579 20.3498C25.816 19.8922 25.8161 19.1502 25.3579 18.6924C24.8996 18.2348 24.1566 18.2348 23.6982 18.6924L11.4297 30.9441H7.16849L33.897 4.2526V28.151C33.897 29.6912 32.6423 30.9441 31.1 30.9441H21.487ZM57.4773 25.6138L28.5593 54.4916C28.4101 54.6405 28.2472 54.7672 28.0759 54.8755V33.2879H31.1001C33.9365 33.2879 36.2441 30.9835 36.2441 28.151V25.131H49.6952L42.9045 31.9123C42.6755 32.1411 42.5608 32.4411 42.5608 32.741C42.5608 33.041 42.6754 33.3407 42.9045 33.5696C43.3628 34.0272 44.1058 34.0272 44.5642 33.5696L53.0136 25.1319C53.0138 25.1317 53.0141 25.1313 53.0143 25.1311H57.8641C57.7544 25.3037 57.6257 25.4658 57.4773 25.6138ZM36.244 22.7873V2.4402C37.1779 2.19118 38.2161 2.4293 38.9473 3.15949L57.4772 21.6637C57.7978 21.9837 58.0303 22.368 58.1641 22.7873H36.244Z"
        fill="white"
      />
      <path
        d="M28.2304 15.8249C28.0122 15.6057 27.7094 15.4815 27.4008 15.4815C27.091 15.4815 26.7894 15.6057 26.5711 15.8249C26.3529 16.0428 26.2273 16.344 26.2273 16.6534C26.2273 16.9616 26.3529 17.2639 26.5711 17.4819C26.7894 17.6998 27.091 17.8252 27.4008 17.8252C27.7094 17.8252 28.0122 17.6998 28.2304 17.4819C28.4487 17.2639 28.5743 16.9616 28.5743 16.6534C28.5743 16.344 28.4487 16.0428 28.2304 15.8249Z"
        fill="white"
      />
      <path
        d="M2.56834 50.43C2.35007 50.2121 2.04731 50.0867 1.73868 50.0867C1.43005 50.0867 1.12729 50.2121 0.909019 50.43C0.690749 50.648 0.565186 50.9503 0.565186 51.2585C0.565186 51.5667 0.690749 51.8691 0.909019 52.087C1.12729 52.305 1.43005 52.4304 1.73868 52.4304C2.04848 52.4304 2.35007 52.305 2.56834 52.087C2.78778 51.8691 2.91217 51.5667 2.91217 51.2585C2.91217 50.9503 2.78778 50.648 2.56834 50.43Z"
        fill="white"
      />
      <path
        d="M15.466 52.6738C15.0076 52.2162 14.2646 52.2162 13.8063 52.6738L8.47677 57.9959C8.24771 58.2247 8.13306 58.5247 8.13306 58.8245C8.13306 59.1244 8.24759 59.4244 8.47677 59.6533C8.93514 60.1109 9.6782 60.1109 10.1364 59.6533L15.466 54.3311C15.9241 53.8734 15.9241 53.1314 15.466 52.6738Z"
        fill="white"
      />
    </svg>
  );
};

export default FlyingLettreSmallIcon;
