import React from "react";

const TimerNew = () => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.6663 6.54297C20.7689 6.54297 20.0413 5.81543 20.0413 4.91797C20.0413 4.02051 20.7689 3.29297 21.6663 3.29297H29.3267C30.2241 3.29297 30.9517 4.02051 30.9517 4.91797C30.9517 5.81543 30.2241 6.54297 29.3267 6.54297H21.6663Z" fill="white" />
      <path d="M14.1487 7.52209C14.7833 8.1567 14.7833 9.18559 14.1487 9.82019L8.73206 15.2369C8.09745 15.8715 7.06856 15.8715 6.43396 15.2369C5.79936 14.6023 5.79936 13.5734 6.43396 12.9388L11.8506 7.52209C12.4852 6.88749 13.5141 6.88749 14.1487 7.52209Z" fill="white" />
      <path d="M25.9997 12.4628C17.3242 12.4628 10.2913 19.4957 10.2913 28.1711C10.2913 36.8466 17.3242 43.8795 25.9997 43.8795C34.6751 43.8795 41.708 36.8466 41.708 28.1711C41.708 27.2737 42.4355 26.5461 43.333 26.5461C44.2305 26.5461 44.958 27.2737 44.958 28.1711C44.958 38.6415 36.4701 47.1295 25.9997 47.1295C15.5293 47.1295 7.04134 38.6415 7.04134 28.1711C7.04134 17.7007 15.5293 9.21281 25.9997 9.21281C26.8971 9.21281 27.6247 9.94035 27.6247 10.8378C27.6247 11.7353 26.8971 12.4628 25.9997 12.4628Z" fill="white" />
      <path d="M37.2408 18.127C37.7439 17.4801 37.6866 16.5598 37.1071 15.9803C36.5276 15.4008 35.6073 15.3435 34.9604 15.8467L28.0923 21.1886L23.6042 24.3943C22.6455 25.0791 22.0766 26.1847 22.0766 27.3629C22.0766 29.3776 23.7099 31.0109 25.7246 31.0109C26.9027 31.0109 28.0084 30.4419 28.6931 29.4832L31.8989 24.9952L37.2408 18.127Z" fill="white" />
    </svg>
  );
};

export default TimerNew;
