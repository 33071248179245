import React from "react";
import domi from "../../assets/png/unsplash_2.png";
import { Row, Col } from 'antd';


const BriefBlock = () => {
  return (
    <>
      <div className={"brief-block"}>
        <div className={"max-width-container"}>
          <Row gutter={[12, 12]}>
            <Col xs={24} md={12}>
              <h2>Domicilier en bref...</h2>
              <div className={"content"}>
                <h3>Notre vision</h3>
                <p>
                  Domicilier.tn apporte la meilleure expérience d’utilisateur à ses clients à travers ses services.
                </p>
              </div>
              <div className={"content"}>
                <h3>Notre message</h3>
                <p>
                  Domicilier.tn consiste à attribuer à votre entreprise une adresse postale prestigieuse avec une solution différente des autres possibilités d’installation de l’entreprise.s
                </p>
              </div>
              <div className={"content"}>
                <h3>Notre objectif</h3>
                <p>
                  Notre objectif est d’accompagner les entrepreneurs dans toutes les formalités liés à la création de leurs sociétés et offrir l’avantage d’une installation à moindre cout.
                </p>
              </div>
            </Col>
            <Col xs={24} md={12} style={{ alignSelf: "flex-end" }}>
              <div className={"image-container"}>
                <img src={domi} alt="img" />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default BriefBlock;
