import { AnyAction, Reducer } from "redux";
import {
  GET_ARTICLES_REQUEST,
  GET_ARTICLES_SUCCESS,
  GET_ARTICLES_FAILURE,
  GET_ARTICLE_REQUEST,
  GET_ARTICLE_SUCCESS,
  GET_ARTICLE_FAILURE,
} from "../actions/blog-actions/types";

const INITIAL_STATE: any = {
  articles: [],
  article: {},
  lastPage: 1,
  total: 0,
  isLoadingArticles: false,
  isLoadingArticle: false,
};

export const blogReducer: Reducer<any, AnyAction> = (
  state: any = INITIAL_STATE,
  action
): boolean => {
  switch (action.type) {

    case GET_ARTICLES_REQUEST:
      return {
        ...state,
        isLoadingArticles: true,
      };
    case GET_ARTICLES_SUCCESS:
      return {
        ...state,
        articles: action.payload.articles,
        total: action.payload.total,
        lastPage: action.payload.lastPage,
        isLoadingArticles: false,
      };
    case GET_ARTICLES_FAILURE:
      return {
        ...state,
        isLoadingArticles: false,
      };
    case GET_ARTICLE_REQUEST:
      return {
        ...state,
        isLoadingArticle: true,
      };
    case GET_ARTICLE_SUCCESS:
      return {
        ...state,
        article: action.payload.article,
        isLoadingArticle: false,
      };
    case GET_ARTICLE_FAILURE:
      return {
        ...state,
        article: action.payload.article,
        isLoadingArticle: false,
      };
    default:
      return state;
  }
};
