import React, { useEffect, useState } from "react";
import "./coming-soon-page.scss";
import { Form, Input, Spin, message } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import axiosInstance from "../../config/axios-instance";
import Counter from "./Counter";

import Saly from "../../assets/png/Saly-16.png";

const antIcon = <LoadingOutlined style={{ fontSize: 24, color: "#3240FF" }} spin />;

const ComingSoonPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => {
    document.title = `Coming Soon | Domicilier`
  })
  const onClickSubscribe = (values: any) => {
    if (values) {
      setIsLoading(true);
      axiosInstance({
        method: "post",
        url: "services/newsletter",
        data: values,
      })
        .then((response) => {
          setIsLoading(false);
          message.success({
            content: 'Vous serez maintenant notifié lors du lancement du produit !',
            className: 'success-message',
            duration: 3
          });
        })
        .catch((e) => {
          setIsLoading(false);
          message.error({
            content: 'Un probème est survenu! Veuillez réessayer plus tard.',
            className: 'error-message',
            duration: 3
          });
        })
      form.resetFields();
    }
  }
  return (
    <div className={"coming-soon-page"} style={{ backgroundImage: `url('${Saly}')` }}>
      <div className="max-width-container">
        <div className={"content"}>
          <div className={"header"}>
            <h1 className={"title"}>
              <strong>Domicilier.tn</strong> <br />
              arrive bientôt avec des mises à jour remarquables
            </h1>
            <p>Notre objectif est toujours de trouver les meilleures personnes avec qui travailler. Notre barre est haute, mais vous semblez prêt à relever le défi.</p>
          </div>
          <Counter
            endDate={'2022/01/01'}
          />
          <p>Soyez notifié lors du lancement du produit !</p>
          <div className={"notify-now"}>
            <Form
              name="notify-me-form"
              onFinish={onClickSubscribe}
              className={"contact-me-form"}
              form={form}
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Tapez votre email SVP!",
                  },
                ]}
              >
                <Input
                  placeholder="E-mail"
                  size="large"
                  suffix={<button type="submit" className={"blue-btn"} >{isLoading ? (<Spin indicator={antIcon} />) : "Envoyer"}</button>}
                />
              </Form.Item>

            </Form>
          </div>
        </div>
      </div>
      <div className={"mobile-image-container"}>
        <img src={Saly} alt="img" />
      </div>
    </div>
  );
};

export default ComingSoonPage;
